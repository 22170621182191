import React, { ReactElement, ReactNode } from "react";
import { Media } from "reactstrap";
import mixopiaIcon from "../images/MIXOPIA_ICON_RGB.svg";
import { Track } from "./EventModel";

interface TrackProps {
  right?: ReactNode;
  track: Track;
}

const TrackDetails = (
  props: React.PropsWithChildren<TrackProps>
): ReactElement => (
  <div>
    <Media className="app-track-details">
      <Media left middle className="mr-3"><img height="72" src={props.track.imageUrl || mixopiaIcon} alt="artwork" /></Media>
      <Media body>
        <Media heading className="mb-0">
          {props.track.name}
        </Media>
        <span className="body-copy-2">{props.track.artist}</span>
      </Media>
      {props.right && <Media right>{props.right}</Media>}
    </Media>
    <div className="app-media-children">{props.children}</div>
  </div>
);
export default TrackDetails;
