import React, { PropsWithChildren } from "react";
import NavMenu from "./NavMenu";

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="app-layout">
      <NavMenu />
      {/* NOTE: The extra div is required for grid-template-rows to work. */}
      <div>{children}</div>
    </div>
  );
};

export default Layout;
