import React, { ReactElement, useState } from "react";
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import LoginMenu from "./api-authorization/LoginMenu";
import logo from "../images/MIXOPIA_LOGO_RGB_BETA.svg";

const NavMenu = (): ReactElement => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const closeNavbar = () => setCollapsed(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow"
        light
      >
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img height="32px" src={logo} alt="Mixopia" />
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!collapsed}
            navbar
          >
            <Nav className="flex-grow" navbar>
              <NavItem>
                <NavLink
                  onClick={closeNavbar}
                  tag={Link}
                  className="text-dark"
                  to="/events"
                >
                  Events
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={closeNavbar}
                  tag={Link}
                  className="text-dark"
                  to="/playlists"
                >
                  Playlists
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={closeNavbar}
                  className="text-dark"
                  href="https://www.mixopia.com/explore"
                >
                  Explore More
                </NavLink>
              </NavItem>
              <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle nav caret  
                  className="text-dark">
                  How it Works
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <NavLink
                      onClick={closeNavbar}
                      className="text-dark"
                      href="https://www.mixopia.com/help/host"
                    >
                      Hosts
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      onClick={closeNavbar}
                      className="text-dark"
                      href="https://www.mixopia.com/help/guest"
                    >
                      Guests
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <NavItem>
                <NavLink
                  onClick={closeNavbar}
                  className="text-dark"
                  href="https://www.mixopia.com/contact"
                >
                  Contact
                </NavLink>
              </NavItem>
              <LoginMenu />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;
