import React, { useState, useEffect, Fragment, useReducer } from "react";
import { getResponse } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import NarrowContent from "./NarrowContent";
import Footer from "./Footer";
import { getMusicServiceName, PlaylistModel, UserModel } from "./EventModel";
import PlaylistSelections from "./PlaylistSelections";
import { Link, useParams } from "react-router-dom";
import LoadingMessage from "./LoadingMessage";
import BackButton from "./BackButton";
import PlaylistView from "./PlaylistView";
import { Button } from "reactstrap";
import ActionsWrapper from "./ActionsWrapper";
import { useAuth } from "./api-authorization/AuthContext";

export interface PlaylistParams {
  playlistId: string;
}
export const Playlist = (): JSX.Element => {
  const { playlistId } = useParams<PlaylistParams>();
  const [error, setError] = useState<Error>();
  const [owner, setOwner] = useState<UserModel>();
  const initialState: PlaylistModel = {
    id: playlistId,
    title: "",
    selections: [],
    numberOfTracks: 0,
    musicService:0
  };
  const [playlist, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetch(`api/playlists/${playlistId}`)
        .then((response) => getResponse(response, 200))
        .then((response) => response.json())
        .then((up: PlaylistModel) => {
          dispatch(up);
          setOwner(up.owner);
        })
        .catch(setError);
    }
  }, [user]);

  function reducer(
    current: PlaylistModel,
    update: Partial<PlaylistModel>
  ): PlaylistModel {
    return { ...current, ...update  };
  }

  async function updatePlaylist(): Promise<void> {
    return await fetch(`api/playlists/${playlist.id}/update`, {
      method: "PUT"
    })
      .then((response) => getResponse(response, 200))
      .then((response) => response.json())
      .then(dispatch)
      .catch(setError);
  }

  return (
    <div className="app-page">
      <NarrowContent>
        <BackButton tag={Link} to="/playlists" />
        {owner && user ? (
          <Fragment>
            <PlaylistView playlist={playlist} />
            <PlaylistSelections
              user={user}
              playlist={playlist}
              onChange={dispatch}
            />
            <ActionsWrapper>
              {owner.id === user.id && (
                <Button
                  color="primary"
                  onClick={updatePlaylist}
                  disabled={playlist.selections.length === 0}
                >
                  Update Playlist
                </Button>
              )}
              {playlist.uri && (
                <Button
                  color="primary"
                  tag="a"
                  href={playlist.uri}
                  target="_new"
                >
                  Open Playlist in&nbsp;{getMusicServiceName(playlist.musicService)}
                </Button>
              )}
            </ActionsWrapper>
          </Fragment>
        ) : (
          <LoadingMessage />
        )}
        <StatusFooter error={error} />
      </NarrowContent>
      <Footer />
    </div>
  );
};

export default Playlist;
