import React, { ReactElement } from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Button,
} from "reactstrap";
import { PlaylistModel} from "./EventModel";
import { FaChevronRight } from "react-icons/fa";

interface SelectPlaylistProps {
  playlists: PlaylistModel[]
  onSelected: (p: PlaylistModel) => void;
  getPlaylistStatus: (p: PlaylistModel) => string;
}

const SelectPlaylist = (props: SelectPlaylistProps): ReactElement => (
  <ListGroup className="app-user-selections__list">
    {props.playlists.map((p) => (
      <ListGroupItem
        action
        tag={Button}
        key={p.id}
        disabled={!!p.uri}
        onClick={(): void => props.onSelected(p)}
      >
        <ListGroupItemHeading className="mb-0 d-flex align-items-center">
          <div className="flex-grow-1">
            <div>{p.title}</div>
            <p className="body-copy-2 fw-light">{props.getPlaylistStatus(p)}</p>
          </div>
          <FaChevronRight size="16" />
        </ListGroupItemHeading>
      </ListGroupItem>
    ))}
  </ListGroup>
);

export default SelectPlaylist;
