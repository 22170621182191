import React, { useCallback, ReactElement} from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import {
  getSelections,
  TrackSelection,
  UserPlaylistProps,
  needsSelections,
} from "./EventModel";
import { getResponse } from "../utils/apiUtils";
import TrackSearch from "./TrackSearch";
import TrackDetails from "./TrackDetails";
import { PlayerContextProvider } from "./PlayerContext";
import Player from "./Player";

export const ConfirmSelectionsLabel = (selections: number): string =>
  selections > 1 ? "Confirm Song Selections" : "Confirm Song Selection";

export const EditSelectionsMessage = (): ReactElement => (
  <p className="body-copy-3">
    You can edit your song selections any time until the event starts.
  </p>
);

const PlaylistSelections = (props: UserPlaylistProps): JSX.Element => {

  const getUserSelections = useCallback((): TrackSelection[] => {
    return getSelections(props.playlist, props.user);
  }, [props.playlist, props.user]);

  const showSelectTrack = useCallback((): boolean => {
    return needsSelections(props.playlist, props.user);
  }, [props.playlist, props.user]);

  const deleteSelection = async (selection: TrackSelection): Promise<void> => {
    try {
      const response = await fetch(
        `api/playlists/${props.playlist.id}/selections/${selection.id}`,
        {
          method: "DELETE",
        }
      );
      await getResponse(response, 204);
      removeSelection(selection);
    } catch (err) {
      console.error(err);
    }
  };

  const removeSelection = (selection: TrackSelection): void => {
    props.playlist.selections = props.playlist.selections.filter(
      (s) => s.id !== selection.id
    );
    props.onChange && props.onChange(props.playlist);
  };

  const selections = getUserSelections();

  return (
    <div>
      <PlayerContextProvider>
        <span className="body-copy-2 fw-bold">Your songs</span>&nbsp;
        <span className="body-copy-2 fw-light">
          {selections.length}/{props.playlist.numberOfTracks}
          &nbsp;selected.
        </span>
        {showSelectTrack() && (
          <TrackSearch
            user={props.user}
            playlist={props.playlist}
            onChange={props.onChange}
          />
        )}
        {selections.length > 0 && (
          <>
            <ListGroup>
              {selections.map((s: TrackSelection) => (
                <ListGroupItem key={s.id}>
                  <TrackDetails
                    track={s.track}
                    right={
                      <Button close onClick={(): Promise<void> => deleteSelection(s)} />
                    }
                  ></TrackDetails>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}
        <Player visible={false} />
      </PlayerContextProvider>
    </div>
  );
};

export default PlaylistSelections;
