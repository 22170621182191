import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { UserModel } from "../EventModel";
import LoadingMessage from "../LoadingMessage";

interface AuthInfo {
  isAuthenticated: boolean;
  user?: UserModel;
}

interface AuthContextType extends AuthInfo {
  logout: () => void;
  login: (user: UserModel) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {

  const [isLoading, setIsLoading] = useState(true);
  const getCurrentUser = (): AuthInfo => {
    const currentUser = localStorage.getItem("mixopia-auth");
    if (currentUser && currentUser !== "undefined") {
      return { isAuthenticated: true, user: JSON.parse(currentUser) };
    } else {
      return { isAuthenticated: false };
    }
  };

  const [authInfo, setAuthInfoInternal] = useState<AuthInfo>(() =>
    getCurrentUser()
  );

  const setAuthInfo = (authInfo: AuthInfo) => {
    localStorage.setItem("mixopia-auth", JSON.stringify(authInfo.user));
    setAuthInfoInternal(authInfo);
  };

  const login = (user: UserModel) =>
    setAuthInfo({ isAuthenticated: true, user });

  const checkAuthentication = async () => {
    try {
      const response = await fetch("/api/user", {
        redirect: "error",
      });
      if (response.ok) {
        const user = await response.json();
        setAuthInfo({ isAuthenticated: true, user: user });
        return;
      } else {
        setAuthInfo({ isAuthenticated: false });
      }
    } catch (error) {
      setAuthInfo({ isAuthenticated: false });
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("mixopia-auth");
    setAuthInfoInternal({ isAuthenticated: false });
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  if (isLoading) {
    return <LoadingMessage message="Authenticating..." />;
  }
  return (
    <AuthContext.Provider
      value={{
        ...authInfo,
        logout,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
