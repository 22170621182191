import React, { ReactElement } from "react";
import { Badge } from "reactstrap";
import { EventPlaylistProps } from "./EventModel";

const SongsSelected = (props: EventPlaylistProps): ReactElement => (
  <Badge pill title="Songs selected">
    {props.playlist.selections.length}/
    {props.playlist.numberOfTracks * props.event.guests.length}&nbsp;
    Songs selected
  </Badge>
);

export default SongsSelected;
