import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { getResponse } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import { LoadState, EventProps } from "./EventModel";
import { Redirect } from "react-router-dom";

interface DeleteEventState extends LoadState {
  modal: boolean;
  deleted: boolean;
}

class DeleteEvent extends Component<EventProps, DeleteEventState> {
  constructor(props: EventProps) {
    super(props);
    this.state = {
      modal: false,
      deleted: false,
    };
    this.deleteEvent = this.deleteEvent.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async deleteEvent(): Promise<void> {
    const evt = this.props.event;
    this.setState({ modal: false });
    fetch(`api/events/${evt.id}`, {
      method: "DELETE",
    })
      .then((response) => getResponse(response, 204))
      .then(() => this.setState({ deleted: true }))
      .catch((err) => this.setState({ modal: false, error: err }));
  }

  confirmDelete(): void {
    this.toggle();
  }

  toggle(): void {
    this.setState({ modal: !this.state.modal });
  }

  render(): React.ReactNode {
    if (this.state.deleted) return <Redirect to="/events" />;
    return (
      <React.Fragment>
        <Button outline onClick={(): void => this.confirmDelete()}>
          Delete Event
        </Button>
        <StatusFooter status={this.state.status} error={this.state.error} />
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Do you really want to delete the Event?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deleteEvent}>
              Yes
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DeleteEvent;
