import {
  EventModel,
  EventParams,
  EventSummaryModel,
  MusicService,
  State,
  LoadState,
  NewPlaylist,
} from "../components/EventModel";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";

export async function getResponse(
  response: Response,
  expected: number
): Promise<Response> {
  if (response.status === expected) {
    return Promise.resolve(response);
  } else {
    return response.text().then((text) => {
      let msg = `An error occured. `;
      if (text) msg += text;
      msg += ` Status Code : ${response.status} `;
      if (response.statusText) msg += response.statusText;
      return Promise.reject(new Error(msg));
    });
  }
}

export function formatDateTime(date: Date): string {
  return new Date(date).toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function formatDate(date: Date): string {
  return new Date(date).toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function isStarted(event: EventModel): boolean {
  return event ? event.state === State.Started : false;
}

export async function fetchEventSummary(
  component: React.Component<RouteComponentProps<EventParams>>
): Promise<EventSummaryModel> {
  component.setState({ error: undefined });
  const eventId = component.props.match.params.eventId;
  return await fetch(`api/events/${eventId}/summary`)
    .then((response) => getResponse(response, 200))
    .then((response) => response.json())
    .then((e) => {
      component.setState({ event: e });
      return e;
    })
    .catch((err) => {
      component.setState({ error: err });
      return Promise.reject(err);
    });
}

export async function createEvent(
  component: React.Component<unknown, LoadState>,
  evt: EventModel
): Promise<EventModel> {
  component.setState({ error: undefined, status: undefined });
  return await fetch("api/events", {
    method: "POST",
    body: JSON.stringify(evt),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((r) => getResponse(r, 201))
    .then((r) => r.json())
    .then((e) => {
      console.log(`Created event: ${e.id}`);
      return e;
    })
    .catch((err) => {
      console.error(err);
      component.setState({ error: err });
      return Promise.reject(err);
    });
}

export async function updateEvent(
  component: React.Component<unknown, LoadState>,
  evt: EventModel
): Promise<EventModel> {
  component.setState({ error: undefined, status: undefined });
  return await fetch(`api/events/${evt.id}`, {
    method: "PUT",
    body: JSON.stringify(evt),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((r) => getResponse(r, 200))
    .then((r) => r.json())
    .then((e) => {
      console.log(`Updated event: ${e.id}`);
      return e;
    })
    .catch((err) => {
      console.error(err);
      component.setState({ error: err });
      return Promise.reject(err);
    });
}

export async function getMusicService(
  component: React.Component,
  e: EventModel
): Promise<MusicService> {
  return await fetch(`api/events/${e.id}/musicservice`)
    .then((response) => getResponse(response, 200))
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
      component.setState({ error: err });
      return Promise.reject(err);
    });
}

export function isStartDateValid(e: EventModel): boolean {
  return moment(e.startTime).isAfter(moment().subtract(1, "days"));
}

export function isTextValid(s: string): boolean {
  return s.length > 0;
}

export const isEventValid = (e: EventModel): boolean =>
  isTextValid(e.name) &&
  isTextValid(e.location) &&
  isTextValid(e.description) &&
  isStartDateValid(e);

export const joinUrl = (e: EventModel): string =>
  `${window.location.origin}/join/${e.id}`;

export const getFullTitle = (e: EventModel): string =>
  `Mixopia Event ${e.name} on ${formatDateTime(e.startTime)} at ${e.location}`;

export async function addEventPlaylist(
  playlist: NewPlaylist,
  component: React.Component,
  e: EventModel
): Promise<void> {
  component.setState({ error: undefined, status: undefined });
  await fetch(`api/events/${e.id}/playlists`, {
    method: "POST",
    body: JSON.stringify(playlist),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((r) => getResponse(r, 201))
    .then((r) => r.json())
    .catch((err) => {
      console.error(err);
      component.setState({ error: err });
      return Promise.reject(err);
    });
}
