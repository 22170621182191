import { Track } from "./EventModel";
import React, { PropsWithChildren, useState } from "react";

export type PlayerContextType = {
  playing: boolean;
  track?: Track;
  togglePlay: () => void;
  playTrack: (t: Track) => void;
  stop: () => void;
};

export const PlayerContext = React.createContext<PlayerContextType>({
  playing: false,
  track: undefined,
  togglePlay: () => {
    //nothing
  },
  playTrack: () => {
    //nothing
  },
  stop: () => {
    //nothing
  },
});

export const PlayerContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const togglePlay = () => setPlayerContext((prevContext) => ({
    ...prevContext,
    playing: !prevContext.playing,
  }));
  const playTrack = (t: Track) => setPlayerContext({ ...playerContext, playing: true, track: t });
  const stop = () => setPlayerContext({ ...playerContext, playing: false, track: undefined });
  const [playerContext, setPlayerContext] = useState<PlayerContextType>(
    {
      playing: false,
      track: undefined,
      togglePlay,
      playTrack,
      stop
    });

  return <PlayerContext.Provider value={playerContext}>{children}</PlayerContext.Provider>
};

export const PlayerContextConsumer = PlayerContext.Consumer;
