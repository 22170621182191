import React, { Component } from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Button,
} from "reactstrap";
import PlaylistView from "./PlaylistView";
import {
  PlaylistModel,
  EventProps,
  LoadState,
  getMusicServiceName,
} from "./EventModel";
import SongsSelected from "./SongsSelected";
import BackButton from "./BackButton";
import ForwardIcon from "./ForwardIcon";
import { getResponse } from "../utils/apiUtils";
import ActionsWrapper from "./ActionsWrapper";
import { Link } from "react-router-dom";

interface EventPlaylistsState extends LoadState {
  playlistId?: string;
}

class EventPlaylists extends Component<EventProps, EventPlaylistsState> {
  constructor(props: EventProps) {
    super(props);
    this.state = {};
    this.nextPlaylist = this.nextPlaylist.bind(this);
    this.getSelectedPlaylist = this.getSelectedPlaylist.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.updatePlaylists();
  }

  getSelectedPlaylist(): PlaylistModel | undefined {
    return this.props.event.playlists.find(
      (p) => p.id === this.state.playlistId
    );
  }

  private nextPlaylist(): void {
    const playlist = this.getSelectedPlaylist();
    if (playlist) {
      const i = this.props.event.playlists.indexOf(playlist);
      const n = i < this.props.event.playlists.length - 1 ? i + 1 : 0;
      const nextPlaylist = this.props.event.playlists[n];
      this.setState({ playlistId: nextPlaylist.id });
    }
  }

  async updatePlaylists(): Promise<void> {
    if (this.props.event) {
      return await fetch(`api/events/${this.props.event.id}/playlists/update`, {
        method: "PUT",
      })
        .then((response) => getResponse(response, 200))
        .then((response) => response.json())
        .catch((err) => this.setState({ error: err }));
    }
  }

  render(): React.ReactNode {
    const playlist = this.getSelectedPlaylist();
    return !playlist ? (
      <React.Fragment>
        <BackButton tag={Link} to={"/edit/" + this.props.event.id} />
        <h1>Event Music</h1>
        <p>
          Select a theme below or open your
          {getMusicServiceName(this.props.event.musicService)} app to start
          playing songs. We recommend shuffling each playlist. Return to mixopia
          to finish the event and share playlists.
        </p>
        <ListGroup>
          <h2>Playlists</h2>
          {this.props.event.playlists.map((p) => (
            <ListGroupItem
              action
              tag={Button}
              key={p.id}
              disabled={!p.uri}
              onClick={(): void => this.setState({ playlistId: p.id })}
            >
              <ListGroupItemHeading>
                {p.title}&nbsp;
                <ForwardIcon />
              </ListGroupItemHeading>
              <SongsSelected event={this.props.event} playlist={p} />
            </ListGroupItem>
          ))}
        </ListGroup>
        {this.props.children}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <BackButton
          onClick={(): void => this.setState({ playlistId: undefined })}
          value="Back To Playlists"
        />
        <PlaylistView playlist={playlist}>
          {playlist.uri && (
            <ActionsWrapper>
              <Button color="primary" tag="a" href={playlist.uri} target="_new">
                Open Playlist in&nbsp;
                {getMusicServiceName(this.props.event.musicService)}
              </Button>
            </ActionsWrapper>
          )}
        </PlaylistView>
        <ActionsWrapper>
          <Button onClick={this.nextPlaylist}>Next Playlist</Button>
        </ActionsWrapper>
      </React.Fragment>
    );
  }
}
export default EventPlaylists;
