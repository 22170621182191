import React, { ReactElement } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { EventProps } from "./EventModel";
import DeletePlaylist from "./DeletePlaylist";
import SongsSelected from "./SongsSelected";

const HostPlaylists = (
  props: React.PropsWithChildren<EventProps>
): ReactElement => (
  <div className="app-host-playlists">
    {props.event.playlists.length ? (
      <ListGroup>
        {props.event.playlists.map((p) => (
          <ListGroupItem key={p.id}>
            <div className="d-flex justify-content-between">
              <div>
                <p className="body-copy-2 mb-1">{p.title}</p>
                <SongsSelected event={props.event} playlist={p} />
              </div>
              <DeletePlaylist event={props.event} playlist={p} />
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    ) : (
      <p>No Playlists are currently selected.</p>
    )}
    {props.children}
  </div>
);

export default HostPlaylists;
