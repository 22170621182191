import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import { LoadState, PlaylistCategory } from "./EventModel";
import { getResponse } from "../utils/apiUtils";
import ActionsWrapper from "./ActionsWrapper";

interface SelectCategoryState extends LoadState {
  categories: PlaylistCategory[];
  selectedCategory: PlaylistCategory;
}

interface SelectCategoryProps {
  onChange: (category: PlaylistCategory) => void;
}

class SelectCategory extends Component<
  SelectCategoryProps,
  SelectCategoryState
> {
  private readonly customCategory: PlaylistCategory = {
    name: "Create your own",
    themes: [],
  };

  constructor(props: SelectCategoryProps) {
    super(props);
    this.state = {
      categories: [this.customCategory],
      selectedCategory: this.customCategory,
    };
    this.getCategories = this.getCategories.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.getCategories();
  }

  async getCategories(): Promise<void> {
    this.setState({ status: undefined, error: undefined });
    await fetch(`api/categories`)
      .then((r) => getResponse(r, 200))
      .then((r) => r.json())
      .then((c) =>
        this.setState({ categories: [this.customCategory].concat(c) })
      )
      .catch((e) => this.setState({ error: e }));
  }

  selectCategory(c: PlaylistCategory): void {
    this.setState({ selectedCategory: c });
    this.props.onChange(c);
  }

  render(): React.ReactNode {
    return (
      <Fragment>
        <p>
          You can add as many playlist themes as you like. Create your own or
          choose from our extensive list of editable suggestions within the
          categories below:
        </p>
        <ActionsWrapper layout="tiles">
          {this.state.categories.map((c) => (
            <Button
              className="btn-variant btn-theme"
              outline
              key={c.name}
              active={this.state.selectedCategory === c}
              onClick={this.selectCategory.bind(this, c)}
            >
              {c.name}
            </Button>
          ))}
        </ActionsWrapper>
      </Fragment>
    );
  }
}

export default SelectCategory;
