import React, { PropsWithChildren } from "react";
import { UserModel } from "./EventModel";

export interface UserProps {
  user?: UserModel
}

export const HostAction: React.FC<PropsWithChildren<UserProps>> = ({ children, user }) =>
  <>{user?.role === "Host" && children}</>;

