import React, { Component, ChangeEvent, ReactNode, Fragment } from "react";
import {
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { UpdateEventState, EditEventStepProps } from "./EventModel";
import EventDetails from "./EventDetails";
import { getFullTitle, joinUrl, updateEvent } from "../utils/apiUtils";
import ActionsWrapper from "./ActionsWrapper";
import CopyButton from "./CopyButton";
import ShareEvent from "./ShareEvent";

class InviteGuests extends Component<EditEventStepProps, UpdateEventState> {
  constructor(props: EditEventStepProps) {
    super(props);
    this.state = { event: this.props.event, modified: false };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>): void {
    const evt = this.state.event;
    evt.description = e.target.value;
    this.setState({ event: evt, modified: true });
  }

  async updateEvent(): Promise<void> {
    if (this.state.modified && this.state.event.description) {
      await updateEvent(this, this.state.event).then((e) =>
        this.setState({ event: e, modified: false })
      );
    }
    this.props.onNext();
  }

  render(): ReactNode {
    return (
      <Fragment>
        <h1>{this.props.title || "Invites & Reminders"}</h1>
        <p>
          Invite your guests to attend your event and add their song selections
          to your playlist themes.
        </p>
        <EventDetails event={this.props.event} />
        <FormGroup>
          <h2>Invite Message</h2>
          <p className="body-copy-2">
            Copy the editable text below into your invite message.
          </p>
          <Input
            name="description"
            value={this.state.event.description}
            maxLength={250}
            rows={5}
            type="textarea"
            onChange={this.handleInputChange}
            invalid={!this.state.event.description.length}
          />
          <CopyButton
            id="copyMessage"
            className="my-2"
            value={this.state.event.description}
            title="Invite Message"
          />
        </FormGroup>
        <h2>Send Invite</h2>
        <p className="body-copy-2">
          The invite message will contain a link to your event page at Mixopia.
        </p>
        <ShareEvent
          event={this.props.event}
          shareUrl={joinUrl(this.props.event)}
          shareUrlTitle="Event Link"
          title={getFullTitle(this.props.event)}
          body={this.state.event.description}
        />
        <ActionsWrapper>
          <Button
            color="primary"
            disabled={!this.state.event.description}
            onClick={this.updateEvent}
          >
            Save and Continue
          </Button>
        </ActionsWrapper>
      </Fragment>
    );
  }
}

export default InviteGuests;
