import React, { FC, ReactNode } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from "react-router-dom";
import { useAuth } from "./AuthContext";

interface AuthorizeRouteProps extends RouteProps {
  role?: string;
}

const AuthorizeRoute: FC<AuthorizeRouteProps> = ({ role, render, ...rest }) => {
  const { isAuthenticated, user } = useAuth();
  const isAuthorized =
    isAuthenticated && (!role || (user && role === user.role));
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps): ReactNode =>
        isAuthorized && render ? (
          render(props)
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { returnUrl: location.pathname } }}
          />
        )
      }
    />
  );
};

export default AuthorizeRoute;
