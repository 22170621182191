import React, { Component } from "react";
import { updateEvent, isEventValid } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import { EventModel, UpdateEventState, EditEventStepProps } from "./EventModel";
import EventDetailsForm from "./EventDetailsForm";
import EventDetails from "./EventDetails";
import ActionsWrapper from "./ActionsWrapper";
import { Button } from "reactstrap";

interface EditEventDetailsState extends UpdateEventState {
  continue: boolean;
}

class EditEventDetails extends Component<
  EditEventStepProps,
  EditEventDetailsState
> {
  constructor(props: EditEventStepProps) {
    super(props);
    this.state = { event: this.props.event, modified: false, continue: false };
    this.eventChanged = this.eventChanged.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
  }

  eventChanged(e: EventModel): void {
    this.setState({ event: e, modified: true });
  }

  async updateEvent(doNext: boolean): Promise<void> {
    if (this.state.modified) await updateEvent(this, this.state.event);
    doNext ? this.props.onNext() : this.props.onSave();
  }

  render(): React.ReactNode {
    return (
      <div className="app-edit-event-details">
        <h1>{this.props.title || this.props.event.name}</h1>
        <h2>Essential Details</h2>
        <EventDetailsForm
          event={this.state.event}
          onEventChanged={this.eventChanged}
        />
        <EventDetails event={this.state.event} />
        <ActionsWrapper>
          <Button
            color="primary"
            type="submit"
            disabled={!isEventValid(this.state.event)}
            onClick={this.updateEvent.bind(this, false)}
          >
            Save
          </Button>
          <Button
            outline
            disabled={!isEventValid(this.state.event)}
            color="primary"
            type="submit"
            onClick={this.updateEvent.bind(this, true)}
          >
            Save and Send Reminders
          </Button>
        </ActionsWrapper>
        <StatusFooter status={this.state.status} error={this.state.error} />
      </div>
    );
  }
}

export default EditEventDetails;
