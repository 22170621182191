import React, { ReactElement } from "react";
import { EventProps } from "./EventModel";

const EventProgress = (props: EventProps): ReactElement => (
  <div className="my-2">
    <h2>Playlist Progress:</h2>
    <p className="body-copy-2 mb-0">
      {props.event.guestsCompleted} guests have completed their song selections.
    </p>
  </div>
);
export default EventProgress;
