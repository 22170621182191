import React, { Component, Fragment } from "react";
import { EditEventStepProps, NewPlaylist } from "./EventModel";
import HostPlaylists from "./HostPlaylists";
import BackButton from "./BackButton";
import ActionsWrapper from "./ActionsWrapper";
import { Button } from "reactstrap";
import StepsControl from "./StepsControl";
import { CreateEventStep } from "./NewEvent";
import AddButton from "./AddButton";
import AddPlaylist from "./AddPlaylist";
import { addEventPlaylist } from "../utils/apiUtils";

interface EditPlaylistState {
  addPlaylist: boolean;
}

class CreatePlaylists extends Component<EditEventStepProps, EditPlaylistState> {
  constructor(props: EditEventStepProps) {
    super(props);
    this.state = { addPlaylist: !props.event.playlists.length };
    this.onBack = this.onBack.bind(this);
    this.toggleAddPlaylist = this.toggleAddPlaylist.bind(this);
    this.addPlaylist = this.addPlaylist.bind(this);
    this.hasTheme = this.hasTheme.bind(this);
  }

  toggleAddPlaylist(): void {
    this.setState({ addPlaylist: !this.state.addPlaylist });
  }
  
  async addPlaylist(p: NewPlaylist): Promise<void> {
    await addEventPlaylist(p, this, this.props.event);
    this.toggleAddPlaylist();
  }

  onBack(): void {
    this.props.onPrevious();
  }
  
  hasTheme = (t: string): boolean => 
    this.props.event.playlists.some((p) => p.title === t)

  render(): React.ReactNode {
    return (
      <div>
        <div>
          {!this.state.addPlaylist ? (
            <Fragment>
              <StepsControl
                max={CreateEventStep.Invites}
                value={CreateEventStep.Playlists}
                left={<BackButton onClick={this.onBack} />}
              />
              <h1>{this.props.title}</h1>
              <HostPlaylists event={this.props.event} />
              <p>Add more playlist themes to continue your event.</p>
              <AddButton
                onClick={this.toggleAddPlaylist}
                value="Add a Playlist"
              />
              <ActionsWrapper>
                <Button
                  color="primary"
                  disabled={!this.props.event.playlists.length}
                  onClick={this.props.onNext}
                >
                  Continue to Invites
                </Button>
                <Button
                  className="btn-variant btn-tertiary"
                  onClick={this.props.onSave}
                  disabled={!this.props.event.playlists.length}
                >
                  Save Progress
                </Button>
              </ActionsWrapper>
            </Fragment>
          ) : (
            <AddPlaylist
              title={this.props.title}
              hideTheme={this.hasTheme}
              addPlaylist={this.addPlaylist}
              onBack={this.toggleAddPlaylist}
              progress={
                <StepsControl
                  max={CreateEventStep.Invites}
                  value={CreateEventStep.Playlists}
                  left={<BackButton onClick={this.onBack} />}
                />
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default CreatePlaylists;
