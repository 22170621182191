import React, { Component, Fragment, ReactElement } from "react";
import PlaylistForm from "./PlaylistForm";
import {
  PlaylistCategory,
  NewPlaylist,
} from "./EventModel";
import SelectCategory from "./SelectCategory";
import SelectTheme from "./SelectTheme";
import BackButton from "./BackButton";

interface AddPlaylistState {
  category?: PlaylistCategory;
  theme?: string;
}

interface AddPlaylistProps {
  title?: string;
  onBack: () => void;
  progress?: ReactElement;
  addPlaylist: (playlist: NewPlaylist) => Promise<void>;
  hideTheme?: (theme: string) => boolean;
}
class AddPlaylist extends Component<AddPlaylistProps, AddPlaylistState> {
  constructor(props: AddPlaylistProps) {
    super(props);
    this.state = {};
    this.setCategory = this.setCategory.bind(this);
    this.setTheme = this.setTheme.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  setCategory(c: PlaylistCategory): void {
    this.setState({ category: c, theme: undefined });
  }

  setTheme(t: string): void {
    this.setState({ theme: t });
  }

  onBack(): void {
    if (this.state.category && this.state.category.themes && this.state.theme) {
      this.setState({ theme: undefined });
    } else if (this.state.category) {
      this.setState({ category: undefined, theme: undefined });
    } else this.props.onBack();
  }

  render(): React.ReactNode {
    return (
      <div>
        {this.props.progress || <BackButton onClick={this.onBack} />}
        <h1>{this.props.title}</h1>
        {this.state.category &&
        (this.state.theme || !this.state.category.themes.length) ? (
          <PlaylistForm
            category={this.state.category}
            theme={this.state.theme}
            addPlaylist={this.props.addPlaylist}
          />
        ) : (
          <Fragment>
            {this.state.category && this.state.category.themes.length ? (
              <SelectTheme
                category={this.state.category}
                onSelected={this.setTheme}
                hideTheme={this.props.hideTheme}
              />
            ) : (
              <SelectCategory onChange={this.setCategory} />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default AddPlaylist;
