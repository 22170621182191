import React from "react";

interface ActionsWrapperProps {
  layout?: "stacked" | "tiles";
}

const ActionsWrapper = (
  props: React.PropsWithChildren<ActionsWrapperProps>
): React.ReactElement => {
  const layoutClassName = `app-actions-wrapper--${props.layout || "stacked"}-layout`;
  const cssClasses = `app-actions-wrapper ${layoutClassName}`;

  return (<div className="app-actions-container">
    <div className={cssClasses}>
    {props.children}
  </div>
  </div>)
};

export default ActionsWrapper;
