import React, { ReactElement } from "react";
import { Media } from "reactstrap";
import { EventSummaryProps } from "./EventModel";
import { formatDateTime } from "../utils/apiUtils";
import eventLogo from "../images/MIXOPIA_EVENT_BG_001.png";

const EventDetails = (
  props: React.PropsWithChildren<EventSummaryProps>
): ReactElement => (
  <div className="my-4">
    <Media>
      <Media left className="mr-4">
        <img src={eventLogo} height="72px" alt="mixopia event"/>
      </Media>
      <Media body>
        <Media heading tag="h4">
          {props.event.name}
        </Media>
         <div className="body-copy-2">
          <div>{props.event.host.displayName}</div>
          <div>{formatDateTime(props.event.startTime)}</div>
          <div>{props.event.location}</div>
        </div>
      </Media>
        {props.children && (
          <Media left>{props.children}</Media>
        )}  
    </Media>
  </div>
);

export default EventDetails;
