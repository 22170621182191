import React, { Component } from "react";
import { PlayerContext } from "./PlayerContext";

interface PlayerProps {
  visible: boolean;
}

class Player extends Component<PlayerProps> {
  static contextType = PlayerContext;
  context!: React.ContextType<typeof PlayerContext>;
  audio: React.RefObject<HTMLAudioElement>;
  constructor(props: PlayerProps) {
    super(props);
    this.audio = React.createRef();
  }

  componentDidMount(): void {
    if (this.audio.current) {
      this.audio.current.onended = this.context.togglePlay;
    }
  }

  componentDidUpdate(): void {
    if (this.audio.current) {
      this.context.playing
        ? this.audio.current.play()
        : this.audio.current.pause();
    }
  }

  componentWillUnmount(): void {
    if (this.audio.current) {
      this.audio.current.pause();
    }
  }

  render(): React.ReactNode {
    return (
      <audio
        className="my-3"
        controls={this.props.visible}
        id="mixopia-sample-player"
        crossOrigin="anonymous"
        preload="metadata"
        controlsList="nodownload"
        ref={this.audio}
        src={this.context.track?.previewUrl || undefined}
        title={this.context.track?.name}
        autoPlay
      />
    );
  }
}

export default Player;
