import React, { ReactElement } from "react";
import { EventUserProps, userSelections, needsSomeSelections } from "./EventModel";
import { FaCheckCircle } from "react-icons/fa";
import { Button, ButtonProps } from "reactstrap";

export const SelectionsComplete = (
  props: React.PropsWithChildren<EventUserProps>
): React.ReactElement => (
  <div className="text-center">
    <FaCheckCircle className="text-success my-5" size="96" />
    <p className="alert-header">
      <span>{userSelections(props)}&frasl;{props.event.selectionsRequired}</span> <span>Song Selections Confirmed!</span></p>
    <span className="alert-body-copy-bold">
      You can edit your selections at any time up until the event.
    </span>
    {props.children}
  </div>
);

export const CompleteSelectionsButton = (
  props: ButtonProps & EventUserProps
): ReactElement => (
  <Button {...props}>
    {needsSomeSelections(props)
      ? "Complete Song Selection"
      : "Edit Song Selections"}
  </Button>
);

