import React, { ReactElement, Fragment, useState } from "react";
import {
  Badge,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Progress,
  Button,
} from "reactstrap";
import {
  PlaylistModel,
  EventPlaylistProps,
  EventProps,
  getSelections,
  EditEventStepProps,
  updatePlaylist,
} from "./EventModel";
import PlaylistSelections, {
  ConfirmSelectionsLabel,
  EditSelectionsMessage,
} from "./PlaylistSelections";
import ActionsWrapper from "./ActionsWrapper";
import BackButton from "./BackButton";
import SelectPlaylist from "./SelectPlaylist";

const OverallSelections = (props: EventProps): ReactElement => (
  <div className="app-progress-status my-3">
    <div className="body-copy-3">
      Overall Song Selections: {props.event.totalSelections}/
      {props.event.totalSelectionsRequired}
    </div>
    <Progress
      className="without-text ml-2"
      value={props.event.totalSelections}
      max={props.event.totalSelectionsRequired}
    />
  </div>
);

const PlaylistGuestsProgress = (props: EventPlaylistProps): ReactElement => (
  <div>
    <h2>Guests Songs</h2>
    <ListGroup className="my-3">
      {props.event.guests
        .filter((u) => u.id !== props.event.host.id)
        .map((g) => (
          <ListGroupItem className="border-0" key={g.id}>
            <ListGroupItemHeading>{g.displayName}</ListGroupItemHeading>
            <Badge pill title="Songs per user">
              {getSelections(props.playlist, g).length}/
              {props.playlist.numberOfTracks} songs selected.
            </Badge>
          </ListGroupItem>
        ))}
    </ListGroup>
  </div>
);

const HostSelections: React.FC<EditEventStepProps> = ({ event, onPrevious, onUpdated, children }) => {
  const [playlist, setPlaylist] = useState<PlaylistModel | undefined>();
  const getPlaylistStatus = (p: PlaylistModel): string =>
    `Overall ${p.selections.length} / ${p.numberOfTracks * event.guests.length} Songs Selected`;
  const showPlaylists = (): void => setPlaylist(undefined);
  const playlistChanged = (updated: PlaylistModel): void => {
    const updatedEvent = updatePlaylist(event, updated);
    onUpdated && onUpdated(updatedEvent);
    setPlaylist(updated);
  };

  return !playlist ? (
    <Fragment>
      <BackButton onClick={onPrevious} />
      <h1>Song Selections</h1>
      <OverallSelections event={event} />
      <h2>Playlists</h2>
      <SelectPlaylist
        playlists={event.playlists}
        getPlaylistStatus={getPlaylistStatus}
        onSelected={setPlaylist}
      />
      <EditSelectionsMessage />
      {children}
    </Fragment>
  ) : (
    <Fragment>
      <BackButton onClick={showPlaylists} />
      <h1>{playlist.title}</h1>
      <PlaylistSelections
        user={event.host}
        playlist={playlist}
        onChange={playlistChanged}
      />
      <PlaylistGuestsProgress event={event} playlist={playlist} />
      <EditSelectionsMessage />
      <ActionsWrapper>
        <Button
          color="primary"
          disabled={playlist.selections.length === 0}
          onClick={showPlaylists}
        >
          {ConfirmSelectionsLabel(playlist.numberOfTracks)}
        </Button>
      </ActionsWrapper>
    </Fragment>
  );
};

export default HostSelections;
