import React, { ReactElement } from "react";
import { UncontrolledCarousel, CarouselItemProps, Container } from "reactstrap";

export const SoundtrackCarousel = (): ReactElement => {
  const items = Array.from(Array(7).keys()).map(
    (n): CarouselItemProps => {
      return {
        src: `${process.env.PUBLIC_URL}/images/soundtrack/soundtrack-0${
          n + 1
        }.svg`,
        altText: "Soundtrack",
        caption: "",
        key: n,
      };
    }
  );

  return (
    <Container className="soundtrack-carousel">
      <UncontrolledCarousel controls={false} items={items} indicators={false} />
    </Container>
  );
};
