import React from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Home from "./components/Home";
import EditEvent from "./components/EditEvent";
import Events from "./components/Events";
import NewEvent from "./components/NewEvent";
import JoinEvent from "./components/JoinEvent";
import GuestEvent from "./components/GuestEvent";
import RunEvent from "./components/RunEvent";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import FinishEvent from "./components/FinishEvent";
import EventLinks from "./components/EventLinks";
import { EventHubProvider } from "./components/EventHubContext";
import "./App.scss";
import EventSummary from "./components/EventSummary";
import Selections from "./components/Selections";
import Playlists from "./components/Playlists";
import Playlist from "./components/Playlist";
import ErrorBoundary from "./components/ErrorBoundary";
import { BrowserRouter, Switch } from "react-router-dom";
import { Login, LoginCallback, Logout } from "./components/api-authorization/Login";
import { AuthProvider } from "./components/api-authorization/AuthContext";

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
              <Route path="/loginCallback">
                <LoginCallback />
              </Route>
              <Route path="/join/:eventId">
                <JoinEvent />
              </Route>
              <Route path="/links/:eventId">
                <EventLinks />
              </Route>
              <AuthorizeRoute
                path="/view/:eventId"
                render={() => <EventSummary />}
              />
              <AuthorizeRoute path="/events" render={() => <Events />} />
              <AuthorizeRoute
                path="/selections"
                render={() => <Selections />}
              />
              <AuthorizeRoute
                exact
                path="/playlists"
                render={() => <Playlists />}
              />
              <AuthorizeRoute
                path="/playlists/:playlistId"
                render={() => <Playlist />}
              />
              <EventHubProvider>
                <AuthorizeRoute
                  path="/newevent"
                  role="Host"
                  render={(props) => <NewEvent {...props} />}
                />
                <AuthorizeRoute
                  path="/edit/:eventId/:step?"
                  role="Host"
                  render={(props) => <EditEvent {...props} />}
                />
                <AuthorizeRoute
                  path="/run/:eventId"
                  role="Host"
                  render={(props) => <RunEvent {...props} />}
                />
                <AuthorizeRoute
                  path="/finish/:eventId"
                  role="Host"
                  render={(props) => <FinishEvent {...props} />}
                />
                <AuthorizeRoute
                  path="/guest/:eventId"
                  render={() => <GuestEvent />}
                />
              </EventHubProvider>
            </Switch>
          </Layout>
        </BrowserRouter>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;
