import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { EventSummaryModel } from "./EventModel";
import NarrowContent from "./NarrowContent";
import LoadingMessage from "./LoadingMessage";
import CollapseButton from "./CollapseButton";
import Footer from "./Footer";
import AddButton from "./AddButton";
import useAPI from "./useAPI";
import { useAuth } from "./api-authorization/AuthContext";
import { EventsList } from "./EventsList";
import StatusFooter from "./StatusFooter";
import { HostAction } from "./HostAction";

const Events = (): ReactElement => {
  const { user } = useAuth();
  const [showActive, setShowActive] = useState(true);
  const [showPast, setShowPast] = useState(false);
  const [error, setError] = useState<Error>();
  const [activeEvents, setActiveEvents] = useState<EventSummaryModel[]>();
  const [pastEvents, setPastEvents] = useState<EventSummaryModel[]>();
  useAPI<EventSummaryModel[]>(
    "api/events/active",
    {},
    setActiveEvents,
    setError
  );
  useAPI<EventSummaryModel[]>("api/events/past", {}, setPastEvents, setError);

  const toggleActive = () => {
    if (activeEvents?.length) {
      setShowActive(!showActive);
    }
  };

  const togglePast = () => {
    if (pastEvents?.length) {
      setShowPast(!showPast);
    }
  };

  return (
    <div className="app-page">
      <NarrowContent>
        <h1>Welcome Back {user?.displayName}</h1>
        <HostAction user={user}>
          <AddButton tag={Link} to="/newevent/" value="Create an Event" />
        </HostAction>
        <>
          {user && activeEvents ? (
            <div className="my-4">
              <CollapseButton
                value="Upcoming Events"
                onClick={toggleActive}
                isOpen={showActive}
              />
              <Collapse className="mt-2" isOpen={showActive}>
                <EventsList events={activeEvents} user={user} />
              </Collapse>
            </div>
          ) : (
            <LoadingMessage />
          )}
          {user && pastEvents ? (
            <div className="my-4">
              <CollapseButton
                value="Past Events"
                onClick={togglePast}
                isOpen={showPast}
              />
              <Collapse className="mt-2" isOpen={showPast}>
                <EventsList events={pastEvents} user={user} />
              </Collapse>
            </div>
          ) : (
            <LoadingMessage />
          )}
        </>
        <StatusFooter error={error} />
      </NarrowContent>
      <Footer />
    </div>
  );
};

export default Events;
