import React, { ReactElement } from "react";
import { EventProps } from "./EventModel";
import { FaCheckCircle } from "react-icons/fa";

const EventCreated = (props: React.PropsWithChildren<EventProps>): ReactElement => (
  <div className="text-center">
    <FaCheckCircle className="text-success my-5" size="96" />
    <p className="alert-header">Event Created</p>
    <span className="alert-body-copy-bold">
      You can edit your selections at any time up until the event.
    </span>
    {props.children}
  </div>
);
export default EventCreated;
