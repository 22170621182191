import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { getResponse } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import { LoadState, PlaylistModel, EventProps } from "./EventModel";

interface DeleteState extends LoadState {
  modal: boolean;
}

interface DeletePlaylistProps extends EventProps {
  playlist: PlaylistModel;
}

class DeletePlaylist extends Component<DeletePlaylistProps, DeleteState> {
  constructor(props: DeletePlaylistProps) {
    super(props);
    this.state = {
      modal: false,
    };
    this.confirmDelete = this.confirmDelete.bind(this);
    this.toggle = this.toggle.bind(this);
    this.deletePlaylist = this.deletePlaylist.bind(this);
  }

  async deletePlaylist(): Promise<void> {
    this.toggle();
    fetch(`api/events/${this.props.event.id}/playlists/${this.props.playlist.id}`, {
      method: "DELETE",
    })
      .then((response) => getResponse(response, 204))
      .then(() => {
        this.props.event.playlists = this.props.event.playlists.filter(
          (p) => p.id !== this.props.playlist.id
        );
      })
      .catch((err) => this.setState({ error: err }));
  }

  confirmDelete(): void {
    this.toggle();
  }

  toggle(): void {
    this.setState({ modal: !this.state.modal });
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <Button close onClick={(): void => this.confirmDelete()} />
        <StatusFooter status={this.state.status} error={this.state.error} />
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Do you really want to delete this Playlist and any selected tracks?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.deletePlaylist}>
              Yes
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DeletePlaylist;
