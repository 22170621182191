import React, { useEffect, useState } from "react";
import StatusFooter from "./StatusFooter";
import { EventProps, UserModel } from "./EventModel";
import LoadingMessage from "./LoadingMessage";
import useAPI from "./useAPI";

interface AddGuestProps extends EventProps {
  onGuestLoaded: (u: UserModel) => void;
}

const AddGuest: React.FC<AddGuestProps> = ({
  event,
  onGuestLoaded,
}): JSX.Element => {
  const [guest, setGuest] = useState<UserModel>();
  const [error, setError] = useState<Error>();

  useAPI<UserModel>(
    `api/events/${event.id}/guests`,
    { method: "POST" },
    setGuest,
    setError
  );

  useEffect(() => {
    if (guest) {
      event.guests.push(guest);
      onGuestLoaded(guest);
    }
  }, [guest]);

  return guest ? (
    <>
      <StatusFooter status="Guest added to event." />
    </>
  ) : (
    <>
      <LoadingMessage message="Loading Guest Data..." />;
      <StatusFooter error={error} />
    </>
  );
};

export default AddGuest;
