import React, { useState } from "react";
import { useParams } from "react-router-dom";
import StatusFooter from "./StatusFooter";
import NoEvent from "./NoEvent";
import { EventParams, EventSummaryModel } from "./EventModel";
import { ListGroup, ListGroupItemHeading, ListGroupItem } from "reactstrap";
import ForwardIcon from "./ForwardIcon";
import EventDetails from "./EventDetails";
import NarrowContent from "./NarrowContent";
import ShareEvent, { linksTitle, linksUrl } from "./ShareEvent";
import Footer from "./Footer";
import useAPI from "./useAPI";

const EventLinks = (): JSX.Element => {
  const { eventId } = useParams<EventParams>();
  const [error, setError] = useState<Error>();
  const [event, setEvent] = useState<EventSummaryModel>();
  useAPI<EventSummaryModel>(
    `api/events/${eventId}/summary`,
    {},
    setEvent,
    setError
  );

  return (
    <div className="app-page">
      <NarrowContent>
        {event ? (
          <>
            <h1 className="alert-header text-center">Mixopia Playlists</h1>
            <EventDetails event={event} />
            {event.playlists && event.playlists.length > 0 && (
              <>
                <ListGroup>
                  <ListGroupItemHeading>Playlist Links</ListGroupItemHeading>
                  {event.playlists
                    .filter((p) => p.uri)
                    .map((p) => (
                      <ListGroupItem
                        action
                        tag="a"
                        href={p.uri}
                        target="_new"
                        key={p.id}
                      >
                        <ListGroupItemHeading>
                          {p.title}&nbsp;
                          <ForwardIcon />
                        </ListGroupItemHeading>
                      </ListGroupItem>
                    ))}
                </ListGroup>
                <p>
                  <strong>Share the event&apos;s playlists</strong> using your
                  preferred messaging service or social media.
                </p>
                <p>
                  Sharing via the below channels will automatically contain a
                  link to the event&apos;s playlists at MIXOPIA.
                </p>
                <ShareEvent
                  event={event}
                  shareUrl={linksUrl(event)}
                  shareUrlTitle="Event Playlists Link"
                  title={linksTitle(event)}
                  body="We hope you enjoyed the Mixopia event. Please follow the link to see the themed playlists including everyone’s song selections so you can re-live the music memories."
                />
              </>
            )}
          </>
        ) : (
          <NoEvent>
            <StatusFooter error={error} />
          </NoEvent>
        )}
      </NarrowContent>
      <Footer />
    </div>
  );
};

export default EventLinks;
