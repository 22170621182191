import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from "reactstrap";
import { getResponse } from "../utils/apiUtils";
import ActionsWrapper from "./ActionsWrapper";
import { Track, ThemeSelections } from "./EventModel";
import Footer from "./Footer";
import LoadingMessage from "./LoadingMessage";
import NarrowContent from "./NarrowContent";
import TrackDetails from "./TrackDetails";

const Selections = (): ReactElement => {
  const [selections, setSelections] = useState<ThemeSelections[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selection, setSelection] = useState<ThemeSelections>();
  const [length, setLength] = useState(10);
  const showMore = () => setLength(length + 10);
  const fetchTracks = async () => {
    fetch(`api/user/selections`)
      .then((response) => getResponse(response, 200))
      .then((response) => response.json())
      .then(setSelections);
  };
  useEffect(() => {
    fetchTracks();
    setIsLoading(false);
  }, []);
  return (
    <div className="app-page">
      <NarrowContent>
        {isLoading && <LoadingMessage />}
        <ListGroup>
          {selections.slice(0, length).map((s: ThemeSelections) => (
            <ListGroupItem
              action
              tag={Button}
              key={s.title}
              onClick={() => setSelection(s)}
            >
              <ListGroupItemHeading>{s.title}</ListGroupItemHeading>
              {selection == s && (
                <ListGroup>
                  {s.tracks.map((t: Track) => (
                    <ListGroupItem key={s.title + t.id}>
                      <TrackDetails
                        track={t}
                      />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              )}
            </ListGroupItem>
          ))}
        </ListGroup>
        <ActionsWrapper>
          <Button color="secondary" outline onClick={showMore}>
            More...
          </Button>
        </ActionsWrapper>
      </NarrowContent>
      <Footer />
    </div>
  );
};

export default Selections;
