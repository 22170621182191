import React, { Component } from "react";
import { PlayerContext } from "./PlayerContext";
import { Track } from "./EventModel";
import playButton from "../images/MIXOPIA_PLAYER_PLAY.svg";
import pauseButton from "../images/MIXOPIA_PLAYER_PAUSE.svg";

interface PlayerControlProps {
  track: Track;
}

class PlayerControl extends Component<PlayerControlProps> {
  static contextType = PlayerContext;
  context!: React.ContextType<typeof PlayerContext>;
  constructor(props: PlayerControlProps) {
    super(props);
    this.updatePlayer = this.updatePlayer.bind(this);
  }

  updatePlayer(): void {
    this.context.playing && this.context.track === this.props.track
      ? this.context.togglePlay()
      : this.context.playTrack(this.props.track);
  }

  componentWillUnmount(): void {
    if (
      this.context.playing &&
      this.context.track?.id === this.props.track.id
    ) {
      this.context.stop();
    }
  }

  render(): React.ReactNode {
    return (
      this.props.track.previewUrl && (
        <button
          className="btn-player"
          onClick={this.updatePlayer}
        >
          {this.context.playing &&
          this.props.track.id === this.context.track?.id ? (
            <img height={52} src={pauseButton} alt="Pause" />
          ) : (
            <img height={52} src={playButton} alt="Play" />
          )}
          <p>Preview</p>
        </button>
      )
    );
  }
}
export default PlayerControl;
