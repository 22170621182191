import React, { ReactElement, useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { PlaylistProps } from "./EventModel";
import TrackDetails from "./TrackDetails";
import SwitchInput from "./SwitchInput";

const PlaylistView = (
  props: React.PropsWithChildren<PlaylistProps>
): ReactElement => {
  const [showNames, setShowNames] = useState(false);
  return (
    <div>
      <h1>{props.playlist.title}</h1>
      <SwitchInput
        id="showNames"
        checked={showNames}
        onChange={() => setShowNames(!showNames)}
        value="Show User Names"
      />
      <ListGroup>
        {props.playlist.selections.map((s) => (
          <ListGroupItem key={s.id}>
            <TrackDetails track={s.track}>
              <span hidden={!showNames} className="body-copy-3">
                {s.user.displayName}
              </span>
            </TrackDetails>
          </ListGroupItem>
        ))}
      </ListGroup>
      {props.children}
    </div>
  );
};

export default PlaylistView;
