import React, { Component, Fragment } from "react";
import AddPlaylist from "./AddPlaylist";
import { EditEventStepProps, NewPlaylist } from "./EventModel";
import HostPlaylists from "./HostPlaylists";
import ActionsWrapper from "./ActionsWrapper";
import { Button } from "reactstrap";
import AddButton from "./AddButton";
import BackButton from "./BackButton";
import { addEventPlaylist } from "../utils/apiUtils";

interface EditPlaylistState {
  addPlaylist: boolean;
}

class EditPlaylists extends Component<EditEventStepProps, EditPlaylistState> {
  constructor(props: EditEventStepProps) {
    super(props);
    this.state = { addPlaylist: !props.event.playlists.length };
    this.toggleAddPlaylist = this.toggleAddPlaylist.bind(this);
    this.addPlaylist = this.addPlaylist.bind(this);
    this.hasTheme = this.hasTheme.bind(this);
  }

  toggleAddPlaylist(): void {
    this.setState({ addPlaylist: !this.state.addPlaylist });
  }

  async addPlaylist(p: NewPlaylist): Promise<void> {
    await addEventPlaylist(p, this, this.props.event);
    this.toggleAddPlaylist();
  }

  hasTheme = (t: string): boolean => 
    this.props.event.playlists.some((p) => p.title === t)

  render(): React.ReactNode {
    return (
      <div>
        {!this.state.addPlaylist ? (
          <Fragment>
            <BackButton onClick={this.props.onSave} />
            <h1>Edit Playlists</h1>
            <HostPlaylists event={this.props.event} />
            <p>Add more playlist themes to continue your event.</p>
              <AddButton
                onClick={this.toggleAddPlaylist}
                value="Add a Playlist"/>
            <ActionsWrapper>
              <Button
                color="primary"
                disabled={!this.props.event.playlists.length}
                onClick={this.props.onNext}
              >
                Save and Send Reminders
              </Button>
              <Button
                className="btn-variant btn-tertiary"
                onClick={this.props.onSave}
                disabled={!this.props.event.playlists.length}
              >
                Save Progress
              </Button>
            </ActionsWrapper>
          </Fragment>
        ) : (
          <AddPlaylist
            title="Select Your Playlist Theme"
            onBack={this.toggleAddPlaylist}
            addPlaylist={this.addPlaylist}
            hideTheme={this.hasTheme}
          />
        )}
      </div>
    );
  }
}

export default EditPlaylists;
