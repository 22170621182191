import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { EventHubContext } from "./EventHubContext";
import { EventModel, EventState, EventParams } from "./EventModel";
import { getResponse } from "../utils/apiUtils";

export class EventComponent<
  P extends EventParams,
  S extends EventState
> extends Component<RouteComponentProps<P>, S> {
  static contextType = EventHubContext;
  context!: React.ContextType<typeof EventHubContext>;

  constructor(props: RouteComponentProps<P>) {
    super(props);
    this.onEventUpdated = this.onEventUpdated.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.fetchEvent();
  }

  componentWillUnmount(): void {
    this.context.connection?.off("eventupdated", this.onEventUpdated);
  }

  onEventUpdated(e: EventModel): void {
    if (e && this.state.event && this.state.event.id === e.id) {
      this.setState({ event: e });
      console.log(`Event ${e.id} updated.`);
    }
  }

  async fetchEvent(): Promise<EventModel> {
    this.setState({ error: undefined });
    const eventId = this.props.match.params.eventId;
    return await fetch(`api/events/${eventId}`, {
    })
      .then((response) => getResponse(response, 200))
      .then((response) => response.json())
      .then((e: EventModel) => {
        this.setState({ event: e });
        this.context.subscribe && this.context.subscribe(e, this.onEventUpdated);
        return e;
      })
      .catch((err) => {
        this.setState({ error: err });
        return err;
      });
  }
}
