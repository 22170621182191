import React, { ReactElement, ReactNode } from "react";
import { Media, Progress } from "reactstrap";

interface StepsControlProps {
  value: number;
  max: number;
  left?: ReactNode;
  right?: ReactNode;
}

const StepsControl = (props: StepsControlProps): ReactElement | null => {
  if (props.value <= 0) {
    return null;
  }
  return (
    <Media className="align-items-center py-2">
      { props.left && (
        <Media left middle className="mr-2">{props.left}</Media>
      )}
      <Media body className="font-weight-bold">
        {props.value <= props.max && (
          <Progress max={props.max} value={props.value}>
            Step {props.value} of {props.max}
          </Progress>
        )}
      </Media>
      { props.right && (
        <Media right middle className="ml-2">{props.right}</Media>
      )}
    </Media>
  );
};

export default StepsControl;
