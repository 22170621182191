import React, { ReactElement } from "react";
import FacebookIcon from "../images/FACEBOOK_ICON.svg";
import InstagramIcon from "../images/INSTAGRAM_ICON.svg";

const SocialMediaLinks = (): ReactElement => (
      <div className="d-flex justify-content-around">
        <a href="https://www.facebook.com/mixopia/">
          <img height="20px" src={FacebookIcon} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/mixopia">
          <img height="20px" src={InstagramIcon} alt="Instagram" />
        </a>
      </div>
);

export default SocialMediaLinks;
