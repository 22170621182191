import React, { Component, FormEvent } from "react";
import { Form, FormGroup, Input, ListGroup, ListGroupItem } from "reactstrap";
import { getResponse } from "../utils/apiUtils";
import {
  LoadState,
  needsSelections,
  Track,
  TrackSelection,
  UserPlaylistProps,
} from "./EventModel";
import TrackDetails from "./TrackDetails";
import SelectTrack from "./SelectTrack";
import PlayerControl from "./PlayerControl";
import StatusFooter from "./StatusFooter";

interface TrackSearchState extends LoadState {
  tracks: Track[];
  selectedTrack?: Track;
  search: string;
  searching: boolean;
}

class TrackSearch extends Component<UserPlaylistProps, TrackSearchState> {
  constructor(props: UserPlaylistProps) {
    super(props);
    this.state = {
      search: "",
      tracks: [],
      searching: false,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = this.search.bind(this);
    this.trackSelected = this.trackSelected.bind(this);
    this.selectionAdded = this.selectionAdded.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async search(searchText: string): Promise<void> {
    if (!this.state.searching && searchText.length > 2) {
      this.setState({
        error: undefined,
        selectedTrack: undefined,
        tracks: [],
        searching: true,
      });
      fetch(`api/search?playlistId=${this.props.playlist.id}&query=${searchText}`)
        .then((response) => getResponse(response, 200))
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            tracks: data,
            selectedTrack: undefined,
            searching: false,
          })
        )
        .catch((err) => this.setState({ searching: false, error: err }));
    }
  }

  handleSearchChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const searchText = e.target.value;
    this.setState({ search: searchText, tracks: [] });
    this.search(searchText);
  }

  trackSelected(track: Track): void {
    this.setState({ selectedTrack: track });
  }

  selectionAdded(s: TrackSelection): void {
    this.props.playlist.selections.push(s);
    this.props.onChange && this.props.onChange(this.props.playlist)
    if (needsSelections(this.props.playlist, this.props.user)) {
      this.setState({ search: "", tracks: [], selectedTrack: undefined });
    }
  }

  handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const input = e.target as HTMLInputElement;
    input.blur();
  };

  render(): React.ReactNode {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup className="mb-1">
          <Input
            id={this.props.playlist.id}
            onChange={this.handleSearchChange}
            value={this.state.search}
            maxLength={100}
            placeholder="Search for your song..."
            type="search"
          />
        </FormGroup>
        <ListGroup>
          {this.state.tracks.map((t) => (
            <ListGroupItem
              action
              key={t.id}
              onClick={(): void => this.trackSelected(t)}
            >
              <TrackDetails
                track={t}
                right={
                  this.state.selectedTrack === t && <PlayerControl track={t} />
                }
              >
                {this.state.selectedTrack === t && (
                  <SelectTrack
                    user={this.props.user}
                    playlist={this.props.playlist}
                    track={t}
                    onSelectionAdded={this.selectionAdded}
                  />
                )}
              </TrackDetails>
            </ListGroupItem>
          ))}
        </ListGroup>
        <StatusFooter status={this.state.status} error={this.state.error} />
      </Form>
    );
  }
}
export default TrackSearch;
