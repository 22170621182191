import React, { ReactElement } from "react";
import { Alert } from "reactstrap";

interface StatusProps {
  status?: string;
  error?: Error;
}

const StatusFooter = (props: StatusProps): ReactElement => (
  <React.Fragment>
    {props.error && (
      <Alert
        className="small"
        color="danger"
        isOpen={props.error !== undefined}
      >
        {props.error?.message}
      </Alert>
    )}
    {props.status && (
      <Alert className="small" isOpen={props.status !== undefined}>
        {props.status ?? ""}
      </Alert>
    )}
  </React.Fragment>
);

export default StatusFooter;
