import React, { Component, Fragment } from "react";
import {
  getResponse,
  isEventValid,
  updateEvent,
  createEvent,
} from "../utils/apiUtils";
import moment from "moment";
import StatusFooter from "./StatusFooter";
import { EventModel, State, LoadState, MusicServiceId } from "./EventModel";
import { RouteComponentProps, withRouter } from "react-router-dom";
import EventDetailsForm from "./EventDetailsForm";
import EventDetails from "./EventDetails";
import NarrowContent from "./NarrowContent";
import StepsControl from "./StepsControl";
import { Button } from "reactstrap";
import ActionsWrapper from "./ActionsWrapper";
import CreatePlaylists from "./CreatePlaylists";
import InviteGuests from "./InviteGuests";
import EventCreated from "./EventCreated";
import { EventHubContext } from "./EventHubContext";
import BackButton from "./BackButton";
import { EditEventStep } from "./EditEvent";

export enum CreateEventStep {
  Details = 1,
  Playlists,
  Invites,
  Created,
}

interface NewEventState extends LoadState {
  event: EventModel;
  continue: boolean;
  step: CreateEventStep;
}
class NewEvent extends Component<RouteComponentProps, NewEventState> {
  static contextType = EventHubContext;
  context!: React.ContextType<typeof EventHubContext>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      step: CreateEventStep.Details,
      continue: false,
      event: {
        id: "",
        name: "",
        description:
          "You have been invited to contribute to the music using MIXOPIA. Follow the link to log in and choose your songs. Please note that your selections will be hidden from other guests until they are played.",
        location: "",
        startTime: moment()
          .add(7, "days")
          .hours(20)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toDate(),
        guests: [],
        playlists: [],
        host: { id: "", userName: "", displayName: "" },
        state: State.New,
        totalSelections: 0,
        selectionsRequired: 0,
        totalSelectionsRequired: 0,
        percentageComplete: 0,
        guestsCompleted: 0,
        musicService: MusicServiceId.Spotify,
      },
    };
    this.saveEvent = this.saveEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.eventUpdated = this.eventUpdated.bind(this);
    this.onEventUpdated = this.onEventUpdated.bind(this);
    this.continue = this.continue.bind(this);
    this.save = this.save.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.saveProgress = this.saveProgress.bind(this);
  }

  componentDidMount(): void {
    this.context.connection?.on("eventupdated", this.onEventUpdated);
  }

  componentWillUnmount(): void {
    this.context.connection?.off("eventupdated", this.onEventUpdated);
  }

  onEventUpdated(e: EventModel): void {
    if (e && this.state.event.id === e.id) {
      this.updateEvent(e);
      console.log(`Event ${e.id} updated.`);
    }
  }

  updateEvent(e: EventModel): void {
    this.setState({ event: e });
  }

  async saveEvent(): Promise<void> {
    if (this.state.event.id === "") {
      createEvent(this, this.state.event)
        .then((e) => this.eventUpdated(e))
        .catch(() => {
          return;
        });
    } else {
      updateEvent(this, this.state.event)
        .then((e) => this.eventUpdated(e))
        .catch(() => {
          return;
        });
    }
  }

  eventUpdated(e: EventModel): void {
    if (!this.state.continue) {
      this.editEvent(e, EditEventStep.Event);
    } else {
      this.setState({
        event: e,
        step: CreateEventStep.Playlists,
        continue: false,
      });
      this.context.subscribe &&
        this.context.subscribe(e, this.updateEvent);
    }
  }

  async openEvent(e: EventModel): Promise<void> {
    if (e.state === State.New) {
      fetch(`api/events/${e.id}/open`, {
        method: "PUT"
      })
        .then((response) => getResponse(response, 200))
        .catch((err) => this.setState({ error: err }));
    }
  }

  continue(): void {
    this.setState({ continue: true });
    this.saveEvent();
  }

  save(): void {
    this.setState({ continue: false });
    this.saveEvent();
  }

  previous(): void {
    const n =
      this.state.step > CreateEventStep.Details
        ? this.state.step - 1
        : CreateEventStep.Details;
    this.setState({ step: n });
  }

  next(): void {
    const n =
      this.state.step < CreateEventStep.Created
        ? this.state.step + 1
        : CreateEventStep.Details;
    this.setState({ step: n });
  }

  editEvent(e: EventModel, step: EditEventStep): void {
    this.openEvent(e).then(() =>
      this.props.history.push(`/edit/${e.id}/${step}`)
    );
  }

  saveProgress(): void {
    this.editEvent(this.state.event, EditEventStep.Event);
  }

  render(): React.ReactNode {
    return (
      <NarrowContent className="app-page app-page-new-event">
        {this.state.step === CreateEventStep.Details && (
          <Fragment>
            <StepsControl
              max={CreateEventStep.Invites}
              value={CreateEventStep.Details}
            />
            <h1>1. Essential Details</h1>
            <p>Let your guests know the details of your event.</p>
            <EventDetailsForm
              event={this.state.event}
              onEventChanged={this.updateEvent}
            />
            <EventDetails event={this.state.event} />
            <ActionsWrapper>
              <Button
                disabled={!isEventValid(this.state.event)}
                color="primary"
                type="submit"
                onClick={this.continue}
              >
                Continue to Playlists
              </Button>
              <Button
                disabled={!isEventValid(this.state.event)}
                className="btn-variant btn-tertiary"
                onClick={this.save}
              >
                Save Progress
              </Button>
            </ActionsWrapper>
          </Fragment>
        )}
        {this.state.step === CreateEventStep.Playlists && (
          <CreatePlaylists
            title="2. Select Your Playlist Themes"
            event={this.state.event}
            onNext={this.next}
            onSave={this.saveProgress}
            onPrevious={this.previous}
          />
        )}
        {this.state.step === CreateEventStep.Invites && (
          <Fragment>
            <StepsControl
              max={CreateEventStep.Invites}
              value={CreateEventStep.Invites}
              left={<BackButton onClick={this.previous} />}
            />
            <InviteGuests
              title="3. Invitations"
              event={this.state.event}
              onNext={this.next}
              onSave={this.saveProgress}
              onPrevious={this.previous}
            />
          </Fragment>
        )}
        {this.state.step === CreateEventStep.Created && (
          <Fragment>
            <EventCreated event={this.state.event}>
              <ActionsWrapper>
                <Button
                  color="primary"
                  onClick={(): void =>
                    this.editEvent(this.state.event, EditEventStep.SelectSongs)
                  }
                >
                  Continue to Song Selection
                </Button>
                <Button
                  color="secondary"
                  onClick={(): Promise<void> =>
                    this.openEvent(this.state.event).then(() =>
                      this.props.history.push("/events")
                    )
                  }
                >
                  Return to Events
                </Button>
              </ActionsWrapper>
            </EventCreated>
          </Fragment>
        )}
        <StatusFooter status={this.state.status} error={this.state.error} />
      </NarrowContent>
    );
  }
}

export default withRouter(NewEvent);
