import React, { ReactElement, ReactNode, Fragment } from "react";
import { Badge, Button, ListGroup, ListGroupItem } from "reactstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import StatusFooter from "./StatusFooter";
import EditEventDetails from "./EditEventDetails";
import EditPlaylists from "./EditPlaylists";
import NoEvent from "./NoEvent";
import {
  EventModel,
  EventParams,
  EventProps,
  EventState,
  State,
} from "./EventModel";
import DeleteEvent from "./DeleteEvent";
import EventDetails from "./EventDetails";
import HostSelections from "./HostSelections";
import EventProgress from "./EventProgress";
import ForwardIcon from "./ForwardIcon";
import InviteGuests from "./InviteGuests";
import { EventComponent } from "./EventComponent";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import {
  SelectionsComplete,
  CompleteSelectionsButton,
} from "./SelectionsComplete";
import BackButton from "./BackButton";
import { ConfirmSelectionsLabel } from "./PlaylistSelections";

export enum EditEventStep {
  Event,
  Details,
  Playlists,
  Invites,
  SelectSongs,
  Complete,
  EditSongs,
}

export interface EditEventState extends EventState {
  step: EditEventStep;
}

export interface EditEventParams extends EventParams {
  step?: string;
}

const RunEventButton = (props: EventProps): ReactElement => (
  <Button
    color="primary"
    disabled={props.event.playlists.length === 0}
    tag={Link}
    to={"/run/" + props.event.id}
  >
    {props.event.state === State.Started
      ? "Continue to the Music"
      : "Start the Event"}
  </Button>
);

const FinishEventButton = (props: EventProps): ReactElement => (
  <Button
    color="secondary"
    disabled={props.event.state !== State.Started}
    tag={Link}
    to={"/finish/" + props.event.id}
  >
    Finish Event
  </Button>
);

class EditEvent extends EventComponent<EditEventParams, EditEventState> {
  constructor(props: RouteComponentProps<EditEventParams>) {
    super(props);
    this.state = {
      step: props.match.params.step ? parseInt(props.match.params.step) : 0,
    };
    this.setStep = this.setStep.bind(this);
    this.next = this.next.bind(this);
    this.showEvent = this.showEvent.bind(this);
  }

  setStep(s: EditEventStep): void {
    this.setState({ step: s });
  }

  showEvent(): void {
    this.setStep(EditEventStep.Event);
  }

  next(): void {
    const n =
      this.state.step < EditEventStep.Complete
        ? this.state.step + 1
        : EditEventStep.Event;
    this.setState({ step: n });
  }

  render(): React.ReactNode {
    return (
      <NarrowContent className="app-page">
        {this.renderPageContent()}
      </NarrowContent>
    );
  }

  renderPageContent(): React.ReactNode {
    if (!this.state.event) {
      return (
        <div>
          <NoEvent>
            <StatusFooter status={this.state.status} error={this.state.error} />
          </NoEvent>
        </div>
      );
    }
    return (
      <Fragment>
        {this.state.step === EditEventStep.Event &&
          this.getEventOverview(this.state.event)}

        {this.state.step === EditEventStep.Details && (
          <EditEventDetails
            event={this.state.event}
            onNext={this.setStep.bind(this, EditEventStep.Invites)}
            onSave={this.showEvent}
            onPrevious={this.showEvent}
          />
        )}

        {this.state.step === EditEventStep.Playlists && (
          <EditPlaylists
            event={this.state.event}
            onNext={this.next}
            onSave={this.showEvent}
            onPrevious={this.showEvent}
          />
        )}

        {this.state.step === EditEventStep.Invites && (
          <Fragment>
            <BackButton onClick={this.showEvent} />
            <InviteGuests
              event={this.state.event}
              onNext={this.next}
              onSave={this.showEvent}
              onPrevious={this.showEvent}
            ></InviteGuests>
          </Fragment>
        )}

        {this.state.step === EditEventStep.SelectSongs && (
          <HostSelections
            event={this.state.event}
            onNext={this.next}
            onSave={this.next}
            onPrevious={this.showEvent}
            onUpdated={this.onEventUpdated}
          >
            <ActionsWrapper>
              <Button color="primary" onClick={this.next}>
                {ConfirmSelectionsLabel(this.state.event.selectionsRequired)}
              </Button>
            </ActionsWrapper>
          </HostSelections>
        )}
        {this.state.step === EditEventStep.Complete && (
          <SelectionsComplete
            event={this.state.event}
            user={this.state.event.host}
          >
            <ActionsWrapper>
              <Button color="primary" tag={Link} to="/events">
                Return to Events Dashboard
              </Button>
              <CompleteSelectionsButton
                event={this.state.event}
                user={this.state.event.host}
                onClick={this.setStep.bind(this, EditEventStep.SelectSongs)}
              />
            </ActionsWrapper>
          </SelectionsComplete>
        )}
        {this.state.step === EditEventStep.EditSongs && (
          <HostSelections
            event={this.state.event}
            onNext={this.showEvent}
            onSave={this.showEvent}
            onPrevious={this.showEvent}
            onUpdated={this.onEventUpdated}
          >
            <ActionsWrapper>
              <RunEventButton event={this.state.event} />
              <Button color="secondary" onClick={this.showEvent}>
                Save and Continue
              </Button>
            </ActionsWrapper>
          </HostSelections>
        )}
      </Fragment>
    );
  }

  private getEventOverview(event: EventModel): ReactNode {
    return (
      <div>
        <h1>{event.name}</h1>
        <ListGroup>
          <ListGroupItem
            key="1"
            tag="button"
            action
            onClick={this.setStep.bind(this, EditEventStep.Details)}
            className="border-0"
          >
            <h2 className="body-copy-2 fw-bold my-2">Essential Details</h2>
            <EventDetails event={event}>
              <ForwardIcon />
            </EventDetails>
            {event.playlists.length > 0 && <EventProgress event={event} />}
          </ListGroupItem>
          <ListGroupItem
            key="2"
            tag="button"
            action
            disabled={event.state === State.Finished}
            onClick={this.setStep.bind(this, EditEventStep.Playlists)}
          >
            <span>Edit Playlists</span>
            <Badge pill title="Number of Playlists">
              {event.playlists.length}
            </Badge>
            <ForwardIcon />
          </ListGroupItem>
          <ListGroupItem
            key="3"
            tag="button"
            action
            disabled={
              event.playlists.length === 0 || event.state === State.Finished
            }
            onClick={this.setStep.bind(this, EditEventStep.Invites)}
          >
            <span>Send Invites</span>
            <Badge pill title="Number of Guests">
              {event.guests.length}
            </Badge>
            <ForwardIcon />
          </ListGroupItem>
          <ListGroupItem
            key="4"
            tag="button"
            action
            disabled={
              event.playlists.length === 0 || event.state === State.Finished
            }
            onClick={this.setStep.bind(this, EditEventStep.EditSongs)}
          >
            <span>View &amp; Edit Song Selections</span>
            <ForwardIcon />
          </ListGroupItem>
          {event.state === State.Finished && (
            <ListGroupItem key="5" action tag={Link} to={"/finish/" + event.id}>
              <span>Share Playlist Links</span>
              <ForwardIcon />
            </ListGroupItem>
          )}
        </ListGroup>

        <ActionsWrapper>
          {event.state <= State.Started && <RunEventButton event={event} />}
          {event.state === State.Started && <FinishEventButton event={event} />}
          <DeleteEvent event={event} />
        </ActionsWrapper>
      </div>
    );
  }
}

export default withRouter(EditEvent);
