import React, { Fragment } from "react";
import { Button } from "reactstrap";
import { getResponse } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import { EventParams, EventState, State } from "./EventModel";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import NoEvent from "./NoEvent";
import ShareEvent, { linksTitle, linksUrl } from "./ShareEvent";
import { EventComponent } from "./EventComponent";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import SocialMediaLinks from "./SocialMediaLinks";

class FinishEvent extends EventComponent<EventParams, EventState> {
  constructor(props: RouteComponentProps<EventParams>) {
    super(props);
    this.state = {};
    this.finishEvent = this.finishEvent.bind(this);
  }

  async finishEvent(): Promise<void> {
    if (this.state.event) {
      fetch(`api/events/${this.state.event.id}/finish`, {
        method: "PUT"
      })
        .then((response) => getResponse(response, 200))
        .catch((err) => this.setState({ error: err }));
    }
  }

  render(): React.ReactNode {
    return (
      <div className="app-page">
        <NarrowContent>{this.renderPageContent()}</NarrowContent>
      </div>
    );
  }

  renderPageContent(): React.ReactNode {
    if (this.state.event) {
      if (this.state.event.state === State.Started) {
        return (
          <Fragment>
            <h1 className="alert-header text-center">Finish Event?</h1>
            <ActionsWrapper>
              <Button color="primary" onClick={this.finishEvent}>
                Finish Event
              </Button>
              <Button outline tag={Link} to={"/run/" + this.state.event.id}>
                Return to Event Music
              </Button>
            </ActionsWrapper>
            <StatusFooter status={this.state.status} error={this.state.error} />
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <h1 className="alert-header text-center">
              Thanks for using Mixopia!
            </h1>
            <p>
              <strong>Share your event&apos;s playlists</strong> with guests and
              friends using your preferred messaging service or social media.
            </p>
            <p>
              Sharing via the below channels will automatically contain a link
              to your event&apos;s playlists at MIXOPIA.
            </p>
            <ShareEvent
              event={this.state.event}
              shareUrl={linksUrl(this.state.event)}
              shareUrlTitle="Event Playlists Link"
              title={linksTitle(this.state.event)}
              body="We hope you enjoyed the Mixopia event. Please follow the link to see the themed playlists including everyone’s song selections so you can re-live the music memories."
            />
            <div className="my-4">
              <p className="body-copy-2">
                Please <strong>like and follow us</strong> on our social media
                pages and share your Mixopia experience.
              </p>
              <SocialMediaLinks />
            </div>
          </Fragment>
        );
      }
    } else
      return (
        <NoEvent>
          <StatusFooter status={this.state.status} error={this.state.error} />
        </NoEvent>
      );
  }
}

export default withRouter(FinishEvent);
