import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import UserSelections from "./UserSelections";
import StatusFooter from "./StatusFooter";
import NoEvent from "./NoEvent";
import { Link, useParams } from "react-router-dom";
import { EventModel, EventParams, UserModel } from "./EventModel";
import {
  SelectionsComplete,
  CompleteSelectionsButton,
} from "./SelectionsComplete";
import AddGuest from "./AddGuest";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import EventDetails from "./EventDetails";
import useAPI from "./useAPI";
import { EventHubContext } from "./EventHubContext";
import { useAuth } from "./api-authorization/AuthContext";

export enum GuestStep {
  Event,
  AddGuest,
  Songs,
  Complete,
}

const GuestEvent = (): JSX.Element => {
  const { eventId } = useParams<EventParams>();
  const { subscribe } = useContext(EventHubContext);
  const [error, setError] = useState<Error>();
  const [step, setStep] = useState<GuestStep>(GuestStep.Event);
  const [guest, setGuest] = useState<UserModel>();
  const [event, setEvent] = useState<EventModel>();
  const { user } = useAuth();

  useAPI<EventModel>(`api/events/${eventId}`, {}, setEvent, setError);

  useEffect(() => {
    if (event && subscribe) {
      subscribe(event, setEvent);
    }
  }, [event]);

  useEffect(() => {
    if (event && user) {
      const guest = event.guests.find((g) => g.id === user?.id);
      if (guest) {
        setGuest(guest);
        setStep(GuestStep.Songs);
      }
      else {
        setStep(GuestStep.AddGuest);
      }
    }
  }, [event, user]);

  const guestLoaded = (u: UserModel): void => {
    setGuest(u);
    setStep(GuestStep.Songs);
  };

  const complete = (): void => {
    setStep(GuestStep.Complete);
  };

  const select = (): void => {
    setStep(GuestStep.Songs);
  };

  return (
    <NarrowContent className="app-page app-page-guest-event">
      {event ? (
        <>
          <EventDetails event={event} />
          {step === GuestStep.AddGuest && <AddGuest event={event} onGuestLoaded={guestLoaded} />}
          {guest && step === GuestStep.Songs && (
            <UserSelections
              event={event}
              user={guest}
              onConfirm={complete}
              onUpdated={(e) => setEvent(e)}
            />
          )}
          {guest && step === GuestStep.Complete && (
            <SelectionsComplete event={event} user={guest}>
              <ActionsWrapper>
                <Button color="primary" tag={Link} to="/events">
                  Return to Events Dashboard
                </Button>
                <CompleteSelectionsButton
                  event={event}
                  user={guest}
                  onClick={select}
                />
              </ActionsWrapper>
            </SelectionsComplete>
          )}
        </>
      ) : (
        <NoEvent>
          <StatusFooter error={error} />
        </NoEvent>
      )}
    </NarrowContent>
  );
};

export default GuestEvent;
