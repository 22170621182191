import React, { useState } from "react";
import { PlaylistModel, EventUserProps, getSelections, EventModel, updatePlaylist } from "./EventModel";
import PlaylistSelections, {
  ConfirmSelectionsLabel,
  EditSelectionsMessage,
} from "./PlaylistSelections";
import GuestProgress from "./GuestProgress";
import SelectPlaylist from "./SelectPlaylist";
import BackButton from "./BackButton";
import ActionsWrapper from "./ActionsWrapper";
import { Button } from "reactstrap";

interface UserSelectionsProps extends EventUserProps {
  onConfirm: () => void;
  onUpdated?: (updatedEvent: EventModel) => void;
}

const UserSelections: React.FC<UserSelectionsProps> = ({ event, user, onConfirm, onUpdated }): JSX.Element => {
  const [playlist, setPlaylist] = useState<PlaylistModel | undefined>();
  const getPlaylistStatus = (p: PlaylistModel): string =>
    `${getSelections(p, user).length} / ${p.numberOfTracks} Songs Selected`;
  const showPlaylists = (): void => setPlaylist(undefined);
  const playlistChanged = (updated: PlaylistModel): void => {
    const updatedEvent = updatePlaylist(event, updated);
    onUpdated && onUpdated(updatedEvent);
    setPlaylist(updated);
  };

  return (
    <div className="app-page app-page-user-selections">
      {!playlist ? (
        <>
          <h2>Song Selection</h2>
          <p>
            Search and select songs that match each playlist theme your host
            has chosen.
          </p>
          <GuestProgress event={event} user={user} />
          <h5>Playlists</h5>
          <SelectPlaylist
            playlists={event.playlists}
            onSelected={setPlaylist}
            getPlaylistStatus={getPlaylistStatus}
          />
          <EditSelectionsMessage />
          <ActionsWrapper>
            <Button color="primary" onClick={onConfirm}>
              {ConfirmSelectionsLabel(event.selectionsRequired)}
            </Button>
          </ActionsWrapper>
        </>
      ) : (
        <>
          <BackButton onClick={showPlaylists} />
          <h2>{playlist.title}</h2>
          <PlaylistSelections
            user={user}
            playlist={playlist}
            onChange={playlistChanged}
          />
          <p>You can edit your selections at any time up until the event.</p>
          <ActionsWrapper>
            <Button
              color="primary"
              onClick={showPlaylists}
              disabled={playlist.selections.length === 0}
            >
              {ConfirmSelectionsLabel(playlist.numberOfTracks)}
            </Button>
          </ActionsWrapper>
        </>
      )}
    </div>
  );
};

export default UserSelections;
