import React, { ReactElement } from "react";
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import { PlaylistCategory } from "./EventModel";

interface SelectThemeProps {
  category: PlaylistCategory;
  onSelected: (t: string) => void;
  hideTheme?: (t: string) => boolean;
}

const SelectTheme = (props: SelectThemeProps): ReactElement => (
  <ListGroup>
    <h4>Category:&nbsp;{props.category.name}</h4>
    {props.category.themes.map((t) => (
      <ListGroupItem
        className="btn-variant btn-theme"
        action
        tag={Button}
        key={t}
        value={t}
        disabled={props.hideTheme && props.hideTheme(t)}
        onClick={(): void => props.onSelected(t)}
      >
        {t}
      </ListGroupItem>
    ))}
  </ListGroup>
);

export default SelectTheme;
