import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import StatusFooter from "./StatusFooter";
import NoEvent from "./NoEvent";
import { EventParams, EventState, State } from "./EventModel";
import StartEvent from "./StartEvent";
import EventPlaylists from "./EventPlaylists";
import { Button } from "reactstrap";
import { EventComponent } from "./EventComponent";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import { isStarted } from "../utils/apiUtils";

class RunEvent extends EventComponent<EventParams, EventState> {
  constructor(props: RouteComponentProps<EventParams>) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    return (
      <NarrowContent className="app-page app-page-run-event">
        {this.renderPageContent()}
      </NarrowContent>
    );
  }

  renderPageContent(): React.ReactNode {
    if (this.state.event) {
      return (
        <div>
          {this.state.event.state < State.Started && (
            <StartEvent event={this.state.event} />
          )}
          {isStarted(this.state.event) && (
              <EventPlaylists event={this.state.event}>
                <ActionsWrapper>
                  <Button
                    color="secondary"
                    tag={Link}
                    to={"/finish/" + this.state.event.id}
                  >
                    Finish Event
                  </Button>
                  <Button
                    className="btn-variant btn-tertiary"
                    tag={Link}
                    to="/events"
                  >
                    Return to Events Dashboard
                  </Button>
                </ActionsWrapper>
              </EventPlaylists>
          )}
        </div>
      );
    } else {
      return (
        <NoEvent>
          <StatusFooter status={this.state.status} error={this.state.error} />
        </NoEvent>
      );
    }
  }
}

export default withRouter(RunEvent);
