import React, { ReactElement } from "react";
import { Button, ButtonProps } from "reactstrap";
import plusLogo from "../images/MIXOPIA_PLUS.svg";

const AddButton = (props: ButtonProps): ReactElement => (
    <Button
    outline
    color="primary"
    className="text-left w-100"
    {...props}
  >
    <img
      src={plusLogo}
      height="34px"
      alt="Add"
      className="pr-2"
    />
    {props.value}
  </Button>
);

export default AddButton;
