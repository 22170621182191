import { useEffect } from "react";

export async function fetchData<T>(
  url: string,
  options?: RequestInit,
  setData?: (data: T) => void,
  onError?: (error: Error) => void): Promise<void> {
  try {
    const response = await fetch(url, {
      ...options,
      redirect: "error",
    });
    if (!response.ok) {
      const error = await response.text();
      if (error !== undefined) {
        throw new Error(`Error: ${error}`);
      } else {
        throw new Error(`API Error: ${response.status}`);
      }}
      
    const body: T = await response.json();
    setData ? setData(body) : console.log(response);
  } catch (error) {
    onError ? onError(error as Error) : console.error(error);
  }
}

const useAPI = <T,>(
  url: string,
  options?: RequestInit,
  setData?: (data: T) => void,
  onError?: (error: Error) => void
): void => {
  useEffect(() => {
    fetchData(url, options, setData, onError);
  }, []);
};

export default useAPI;
