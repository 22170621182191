import React, { useState } from "react";
import { Button } from "reactstrap";
import { getResponse } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import {
  LoadState,
  Track,
  TrackSelection,
  UserPlaylistProps,
} from "./EventModel";
import ActionsWrapper from "./ActionsWrapper";

interface SelectTrackProps extends UserPlaylistProps {
  track: Track;
  onSelectionAdded: (s: TrackSelection) => void;
}

const SelectTrack: React.FC<SelectTrackProps> = ({
  user,
  playlist,
  track,
  onSelectionAdded,
}) => {
  const [state, setState] = useState<LoadState>({});

  const sendSelection = async (): Promise<void> => {
    setState({
      error: undefined,
      status: undefined,
    });
    await fetch(`api/playlists/${playlist.id}/selections`, {
      method: "POST",
      body: JSON.stringify(track),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => getResponse(r, 200))
      .then((r) => r.json())
      .then((s) => selectionAdded(s))
      .catch((err) => setState({ error: err }));
  };

  const selectionAdded = (s: TrackSelection): void => {
    onSelectionAdded(s);
  };

  const isSelected = (): boolean =>
    playlist.selections &&
    playlist.selections.some(
      (s) => s.user.id === user.id && s.track.id === track.id
    );

  const isTrackInPlaylist = (): boolean => {
    return playlist.selections.some(
      (s) => s.track.id === track.id
    );
  };

  return (
    <ActionsWrapper>
      {isTrackInPlaylist() &&
        <div className="body-copy-3">
          This song has already been added to the playlist.<br />
          Are you sure you want to add it again?
        </div>}
      <Button
        color="primary"
        disabled={isSelected()}
        onClick={sendSelection}
      >
        Add Song to Playlist
      </Button>
      <StatusFooter status={state.status} error={state.error} />
    </ActionsWrapper>
  );
};

export default SelectTrack;
