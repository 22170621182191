import React, { ReactElement, useState } from "react";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import StatusFooter from "./StatusFooter";
import { EventSummaryProps, UserModel } from "./EventModel";
import ActionsWrapper from "./ActionsWrapper";
import { fetchData } from "./useAPI";
import { Redirect } from "react-router-dom";
import { useAuth } from "./api-authorization/AuthContext";

export interface ErrorModel {
  code: string;
  description: string;
}

interface NewUserModel {
  userName: string;
  displayName: string;
  password: string;
}

const NewGuest: React.FC<EventSummaryProps> = ({ event }): ReactElement => {
  const [newUser, setNewUser] = useState<NewUserModel>({
    userName: "",
    displayName: "",
    password: "",
  });
  const [error, setError] = useState<Error>();
  const validUserName = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newUser.userName);
  const validPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(newUser.password);
  const formValid = validUserName && validPassword && newUser.displayName.length > 0;
  const handleUserNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setNewUser({ ...newUser, userName: e.target.value });
  };
  const handleDisplayNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setNewUser({ ...newUser, displayName: e.target.value });
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({ ...newUser, password: e.target.value });
  };

  const { login, isAuthenticated } = useAuth();

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    await fetchData<UserModel>(
      `api/events/${event.id}/newguest`,
      {
        method: "POST",
        body: JSON.stringify(newUser),
        headers: {
          "Content-Type": "application/json",
        },
      },
      login,
      setError);
  };



  return !isAuthenticated ? (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="name">Email</Label>
        <Input
          id="email"
          name="email"
          value={newUser.userName}
          maxLength={250}
          type="email"
          onChange={handleUserNameChange}
          invalid={!validUserName}
          valid={validUserName}
        />
        <FormFeedback>Email is required.</FormFeedback>
        <Label for="displayName">Name</Label>
        <Input
          id="displayName"
          name="displayName"
          value={newUser.displayName}
          maxLength={250}
          type="text"
          onChange={handleDisplayNameChange}
          invalid={!newUser.displayName.length}
          valid={newUser.displayName.length > 0}
        />
        <FormFeedback>Name is required.</FormFeedback>
        <Label for="password">Password</Label>
        <Input
          id="password"
          name="password"
          type="password"
          onChange={handlePasswordChange}
          invalid={!validPassword}
          valid={validPassword}
        />
        <FormFeedback>Password is required. Requires at least 6 characters including upper and lower case letters, and at least one number.</FormFeedback>
      </FormGroup>
      <ActionsWrapper>
        <Button
          color="primary"
          type="submit"
          disabled={!formValid}
          tag="button"
        >
          Join as Guest &amp; Select Songs
        </Button>
      </ActionsWrapper>
      <StatusFooter
        error={error}
      />
    </form>
  ) : <Redirect to={`/guest/${event.id}`} />;
};

export default NewGuest;
