import * as React from "react";
import { ReactElement } from "react";
import { Card, CardHeader } from "reactstrap";

const NoEvent = (props: React.PropsWithChildren<unknown>): ReactElement => (
  <Card>
    <CardHeader>No Event Loaded</CardHeader>
    {props.children}
  </Card>
);
export default NoEvent;
