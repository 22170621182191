import * as React from "react";
import { ReactElement } from "react";
import { Spinner } from "reactstrap";

interface LoadingMessageProps {
  message?: string;
}

const LoadingMessage = (props: LoadingMessageProps): ReactElement => {
  const message = props.message || 'Loading...';
  return <div className="d-flex align-items-center justify-content-center my-5">
    <Spinner color="primary" role="presentation" aria-hidden="true" />
    <span role="status" className="ml-2">{message}</span>
  </div>
};

export default LoadingMessage;
