import React, { Fragment } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import Footer from "./Footer";
import { SoundtrackCarousel } from "./SoundtrackCarousel";
import { useAuth } from "./api-authorization/AuthContext";

const Home: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const renderStartButton = (): React.ReactNode => {
    return isAuthenticated ? (
      <Button color="primary" tag={Link} to="/events">
        My Events
      </Button>
    ) : (
      <Fragment>
        <Button
          color="primary"
          tag={Link}
          to={ApplicationPaths.IdentityRegisterPath}
        >
          Create your first event
        </Button>
        <Button color="secondary" tag={Link} to="/events">
          Login
        </Button>
      </Fragment>
    );
  };

  return (
    <div>
      <SoundtrackCarousel />
      <NarrowContent className="my-3 text-center">
        <h1>Soundtrack your life</h1>
        <p>
          Soundtrack your next event or gathering with themed, collaborative
          playlists to relive music memories and create new ones.
        </p>
        <ActionsWrapper>{renderStartButton()}</ActionsWrapper>
      </NarrowContent>
      <Footer />
    </div>
  );
};

export default Home;
