import React, { Fragment, ReactElement } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { EventSummaryModel, EventSummaryProps } from "./EventModel";
import CopyButton from "./CopyButton";

interface ShareEventProps extends EventSummaryProps {
  shareUrl: string;
  shareUrlTitle: string;
  title: string;
  body: string;
}

export const linksUrl = (e: EventSummaryModel): string =>
`${window.location.origin}/links/${e.id}`;

export const linksTitle = (e: EventSummaryModel): string =>
`Playlists for Mixopia Event ${e.name} Hosted by ${e.host.displayName}`;

const ShareEvent = (
  props: React.PropsWithChildren<ShareEventProps>
): ReactElement => (
  <Fragment>
    <ListGroup>
      <ListGroupItem action>
        <FacebookShareButton url={props.shareUrl} quote={props.title}>
          <FacebookIcon size={32} round /> <span>Send via Facebook</span>
        </FacebookShareButton>
      </ListGroupItem>
      <ListGroupItem action>
        <WhatsappShareButton url={props.shareUrl} title={props.title}>
          <WhatsappIcon size={32} round /> <span>Send via Whatsapp</span>
        </WhatsappShareButton>
      </ListGroupItem>
      <ListGroupItem action>
        <EmailShareButton
          url={props.shareUrl}
          subject={props.title}
          body={props.body}
        >
          <EmailIcon size={32} round /> <span>Send via Email</span>
        </EmailShareButton>
      </ListGroupItem>
    </ListGroup>
    <h2>{props.shareUrlTitle}</h2>
    <p className="body-copy-2">
      To send via another channel, copy the link below.
    </p>
    <div className="d-flex align-items-center">
      <CopyButton
        className="mr-2"
        id="copyEventUrlButton"
        value={props.shareUrl}
        title="Link to Event"
      ></CopyButton>
      <a className="event-link" href={props.shareUrl}>
        {props.shareUrl}
      </a>
    </div>
  </Fragment>
);

export default ShareEvent;
