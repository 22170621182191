import { ReactElement } from "react";

export enum State {
  New,
  Open,
  Started,
  Finished,
}

export enum MusicServiceId {
  Spotify,
  AppleMusic
}

export interface EventSummaryModel {
    id: string;
    name: string;
    description: string;
    location: string;
    startTime: Date;
    state: State;
    host: UserModel;
    playlists: PlaylistModel[];
}

export interface EventModel extends EventSummaryModel {
  guests: UserModel[];
  totalSelections: number;
  selectionsRequired: number;
  totalSelectionsRequired: number;
  percentageComplete: number;
  guestsCompleted: number;
  musicService: MusicServiceId;
}

export interface UserModel {
  id: string;
  userName: string;
  displayName: string;
  role?: string;
}

export interface PlaylistModel {
  id: string;
  title: string;
  numberOfTracks: number;
  selections: TrackSelection[];
  uri?: string;
  owner?: UserModel;
  musicService: MusicServiceId;
}

export interface TrackSelection {
  id: string;
  user: UserModel;
  track: Track;
}

export interface ThemeSelections {
  title: string;
  tracks: Track[];
}

export interface Track {
  id: string;
  name: string;
  artist: string;
  uri: string;
  previewUrl: string;
  imageUrl: string;
}

export interface EventParams {
  eventId: string;
}

export interface LoadState {
  status?: string;
  error?: Error;
}

export interface EventState extends LoadState {
  event?: EventModel;
}

export interface UpdateEventState extends LoadState {
  event: EventModel;
  modified: boolean;
}

export interface EventSummaryProps {
  event: EventSummaryModel;
}

export interface EventSummaryState extends LoadState {
    event?: EventSummaryModel;
}

export interface EventProps {
  event: EventModel;
  children?: React.ReactNode;
}

export interface EventUserProps extends EventProps {
  user: UserModel;
}

export interface EventPlaylistProps extends EventProps {
  playlist: PlaylistModel;
}

export interface PlaylistPlayerProps extends EventProps {
  musicService: MusicService;
  playlist: PlaylistModel;
}
export interface NewPlaylist {
  title?: string;
  numberOfTracks: number;
}


export interface EditEventStepProps extends EventProps {
  title?: string;
  onNext: () => void;
  onSave: () => void;
  onPrevious: () => void;
  progress?: ReactElement;
  onUpdated?: (updatedEvent: EventModel) => void;
  children?: React.ReactNode;
}

export function setProperty<T, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K]
): void {
  obj[key] = value;
}

export interface MusicService {
  appToken: string;
  userToken?: string;
  countryCode: string;
}

export interface PlaylistCategory {
  name: string;
  themes: string[];
}

export interface UserPlaylistProps {
  user: UserModel;
  playlist: PlaylistModel;
  onChange?: (p: PlaylistModel) => void;
}

export interface PlaylistProps {
  playlist: PlaylistModel;
}

export const getSelections = (
  p: PlaylistModel,
  u: UserModel
): TrackSelection[] => p.selections.filter((s) => s.user.id === u.id);

export const userSelections = (props: EventUserProps): number =>
  props.event.playlists.reduce(
    (a, p) => a + getSelections(p, props.user).length,
    0
  );

export const needsSelections = (p: PlaylistModel, u: UserModel): boolean =>
  getSelections(p, u).length < p.numberOfTracks;

export const needsSomeSelections = (props: EventUserProps): boolean =>
  props.event.playlists.some((p) => needsSelections(p, props.user));

export const getMusicServiceName = (s: MusicServiceId): string =>
    s === MusicServiceId.Spotify
      ? "Spotify" : "Apple Music";
  
export const updatePlaylist = (event: EventModel, playlist: PlaylistModel): EventModel => {
  const updatedIndex = event.playlists.findIndex(p => p.id == playlist.id);
  const updatedPlaylists = [
    ...event.playlists.slice(0, updatedIndex),
    playlist,
    ...event.playlists.slice(updatedIndex + 1),
  ];
  const updatedEvent = { ...event, playlists: updatedPlaylists };
  return updatedEvent;
}
