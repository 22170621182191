import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem, Media } from "reactstrap";
import { formatDate } from "../utils/apiUtils";
import eventLogo from "../images/MIXOPIA_EVENT_BG_001.png";
import ForwardIcon from "./ForwardIcon";
import { EventSummaryModel, UserModel, State } from "./EventModel";

export interface EventsProps {
  events: EventSummaryModel[];
  user: UserModel;
}

const getEventLink = (e: EventSummaryModel, u: UserModel): string => {
  if (u && e.host.id === u.id) {
    return "/edit/" + e.id;
  }
  if (e.state === State.Finished) {
    return "/links/" + e.id;
  }
  return "/guest/" + e.id;
};

export const EventsList = (props: EventsProps): ReactElement => (
  <ListGroup>
    {props.events.map((e) => (
      <ListGroupItem
        key={e.id}
        tag={Link}
        to={getEventLink(e, props.user)}
        action
      >
        <Media>
          <Media left className="pr-2">
            <img src={eventLogo} height="72px" alt="mixopia event" />
          </Media>
          <Media body>
            <Media heading>
              <span className="body-copy-2 fw-bold">{e.name}</span>
            </Media>
            <Media bottom>
              <div className="body-copy-2">{e.host.displayName}</div>
              <div className="body-copy-2">{formatDate(e.startTime)}</div>
            </Media>
          </Media>
          <Media right>
            <ForwardIcon />
          </Media>
        </Media>
      </ListGroupItem>
    ))}
  </ListGroup>
);
