import React, { Component } from "react";
import { Button } from "reactstrap";
import { getResponse } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import { EventProps, LoadState, getMusicServiceName } from "./EventModel";
import { Link } from "react-router-dom";
import ActionsWrapper from "./ActionsWrapper";
import { EditEventStep } from "./EditEvent";

class StartEvent extends Component<EventProps, LoadState> {
  constructor(props: EventProps) {
    super(props);
    this.state = {};
    this.startEvent = this.startEvent.bind(this);
  }

  async startEvent(): Promise<void> {
    const eventId = this.props.event.id;
    fetch(`api/events/${eventId}/start`, {
      method: "PUT",
    })
      .then((response) => getResponse(response, 200))
      .catch((err) => this.setState({ error: err }));
  }

  render(): React.ReactNode {
    return (
      <div className="text-center">
        <p className="alert-header">Ready to Start Your Event?</p>
        <p className="alert-body-copy-bold">
          Before you do, check your guests have completed their song selections
          as no additions or changes will be allowed once you continue to the
          event music.
        </p>
        <ActionsWrapper>
          <Button
            color="secondary"
            tag={Link}
            to={`/edit/${this.props.event.id}/${EditEventStep.EditSongs}`}
          >
            Check Playlist Contributions
          </Button>
        </ActionsWrapper>
        <p className="alert-body-copy-bold mt-4">
          Continue to Event Music to access the event playlists in&nbsp;
          {getMusicServiceName(this.props.event.musicService)}.
        </p>
        <ActionsWrapper>
          <Button
            color="primary"
            onClick={this.startEvent}
            disabled={this.props.event.totalSelections === 0}
          >
            Continue to Event Music
          </Button>
          <Button
            className="btn-variant btn-tertiary"
            tag={Link}
            to={`/edit/${this.props.event.id}/${EditEventStep.Event}`}
          >
            Return to Event
          </Button>
        </ActionsWrapper>
        {this.props.children}
        <StatusFooter status={this.state.status} error={this.state.error} />
      </div>
    );
  }
}

export default StartEvent;
