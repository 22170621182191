import React, { ReactElement } from "react";
import { ButtonProps } from "reactstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface CollapseButtonProps extends ButtonProps {
  isOpen: boolean;
}
const CollapseButton = (props: CollapseButtonProps): ReactElement => {
  const { isOpen, ...other } = props;
  return (
    <button className="btn-collapse" size="sm" {...other}>
      <span className="fw-bold pr-2">{props.value}</span>
      {isOpen ? <FaChevronUp size="16" /> : <FaChevronDown size="16" />}
    </button>
  );
};

export default CollapseButton;
