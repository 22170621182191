import React, { ReactElement } from "react";
import { EventUserProps, userSelections } from "./EventModel";
import { Progress } from "reactstrap";

const GuestProgress = (props: EventUserProps): ReactElement => (
  <div className="app-progress-status my-3">
    <div>
      Song Selections: {userSelections(props)}/
      {props.event.selectionsRequired}
    </div>
    <Progress
      className="without-text ml-2"
      value={userSelections(props)}
      max={props.event.selectionsRequired}
    />
  </div>
);

export default GuestProgress;
