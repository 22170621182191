import React, { ReactElement, useRef } from "react";
import {
  Button,
  InputProps,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { FaMinus, FaPlus } from "react-icons/fa";

const NumberInput = (props: InputProps): ReactElement => {
  const numberInput = useRef<HTMLInputElement>(null);
  const bubbleEvent = (): void => {
    const event = new Event("input", { bubbles: true });
    numberInput.current?.dispatchEvent(event);
  };
  const onPlusButtonClick = (): void => {
    numberInput.current?.stepUp();
    bubbleEvent();
  };
  const onMinusButtonClick = (): void => {
    numberInput.current?.stepDown();
    bubbleEvent();
  };
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <Button size="sm" onClick={onMinusButtonClick}>
          <FaMinus size="16" />
        </Button>
      </InputGroupAddon>
      <Input type="number" innerRef={numberInput} {...props}></Input>
      <InputGroupAddon addonType="append">
        <Button size="sm" onClick={onPlusButtonClick}>
          <FaPlus size="16" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default NumberInput;
