import React, { useEffect, useState } from "react";
import { AuthRedirect } from "./AuthRedirect";
import { useAuth } from "./AuthContext";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { Redirect, useLocation } from "react-router-dom";
import LoadingMessage from "../LoadingMessage";

interface LoginState {
  returnUrl: string;
}

export const Login: React.FC = (): JSX.Element => {
  const location = useLocation<LoginState | undefined>();
  const returnUrl = location.state?.returnUrl ?? "/Home";
  localStorage.setItem("mixopia-returnUrl", returnUrl);
  return (
    <AuthRedirect
      path={ApplicationPaths.IdentityLoginPath}
    />
  );
};

export const LoginCallback: React.FC = (): JSX.Element => {
  const returnUrl = localStorage.getItem("mixopia-returnUrl") ?? "/Home";
  localStorage.removeItem("mixopia-returnUrl");
  return <Redirect to={returnUrl} />;
};

export const Logout: React.FC = (): JSX.Element => {
  const { logout } = useAuth();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    logout();
    setShouldRedirect(true);
  }, []);
  return shouldRedirect ? (
    <AuthRedirect path={ApplicationPaths.IdentityLogoutPath} />
  ) : (
    <LoadingMessage message="Logging out.." />
  );
};
