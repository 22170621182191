import React, { createContext, useEffect, useState } from "react";
import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
  HubConnectionState,
} from "@microsoft/signalr";
import { EventModel } from "./EventModel";

interface EventHubContextType {
  connection: HubConnection | undefined;
  subscribe?: (e: EventModel, onEventUpdated: (e: EventModel) => void) => void;
}

const connection : HubConnection = new HubConnectionBuilder()
  .withUrl("/hub")
  .withAutomaticReconnect()
  .configureLogging(LogLevel.Information)
  .build();

export const EventHubContext = createContext<EventHubContextType>({
  connection: connection,
});

export const EventHubProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [event, setEvent] = useState<EventModel>();

  const subscribe = async (e: EventModel, onEventUpdated: (e: EventModel) => void) => {
    if (event?.id !== e.id) {
      connection.state === HubConnectionState.Connected
        ? connection.invoke("subscribe", e.id)
          .then(() => setEvent(e))
          .then(() => connection.on("eventupdated", (e: EventModel): void => { onEventUpdated(e); }))
        : setTimeout(() => subscribe(e, onEventUpdated), 1000);
    }
  };

  useEffect(() => {
    const startConnection = async () => {
      if (connection?.state === HubConnectionState.Disconnected) {
        try {
          await connection.start();
          console.log("Connection successfully started");
          connection.on("receivemessage", (m: string) => {
            console.log(`Received Message: ${m}`);
          });
          connection.on("eventupdated", (e: EventModel) => {
            console.log(`Received EventUpdated: ${e.id}`);
            setEvent(e);
          });
          connection.onreconnected(() => {
            if (event) {
              connection.send("subscribe", event.id);
            }
          });
        } catch (err) {
          console.log("Error while establishing connection :(", err);
        }
      }
    };

    startConnection();

    return () => {
      connection?.stop();
    };
  }, []);

  return (
    <EventHubContext.Provider value={{ connection, subscribe }}>
      {children}
    </EventHubContext.Provider>
  );
};
