import React, { Component } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";
import {
  EventModel,
  EventProps,
  setProperty,
  UpdateEventState,
} from "./EventModel";
import { isTextValid, isStartDateValid } from "../utils/apiUtils";

interface EditEventProps extends EventProps {
  onEventChanged: (e: EventModel) => void;
}

class EventDetailsForm extends Component<EditEventProps, UpdateEventState> {
  constructor(props: EditEventProps) {
    super(props);
    this.state = {
      event: this.props.event,
      modified: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const evt = this.state.event;
    const name = e.target.name as keyof EventModel;
    setProperty(evt, name, e.target.value);
    this.setState({ event: evt, modified: true });
    this.props.onEventChanged(evt);
  }

  handleDateChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.valueAsDate) {
      const evt = this.state.event;
      const value = e.target.valueAsDate;
      const newDate = new Date(evt.startTime);
      newDate.setDate(value.getUTCDate());
      newDate.setMonth(value.getUTCMonth());
      newDate.setFullYear(value.getUTCFullYear());
      evt.startTime = newDate;
      this.setState({ event: evt, modified: true });
      this.props.onEventChanged(evt);
    }
  }

  handleTimeChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.valueAsDate) {
      const evt = this.state.event;
      const value = e.target.valueAsDate;
      const newDate = new Date(evt.startTime);
      newDate.setHours(value.getUTCHours());
      newDate.setMinutes(value.getUTCMinutes());
      evt.startTime = newDate;
      this.setState({ event: evt, modified: true });
      this.props.onEventChanged(evt);
    }
  }

  render(): React.ReactNode {
    return (
      <Form>
        <FormGroup>
          <Label for="eventname">Event Name</Label>
          <Input
            id="eventname"
            name="name"
            autoComplete="off"
            value={this.state.event.name}
            onChange={this.handleInputChange}
            maxLength={100}
            type="text"
            placeholder="Event name"
            valid={isTextValid(this.state.event.name)}
            invalid={!isTextValid(this.state.event.name)}
          />
          <FormFeedback>Name is required.</FormFeedback>
        </FormGroup>
        <Row form>
          <Col xs="6">
            <FormGroup>
              <Label for="startDate">Event Date</Label>
              <Input
                id="startDate"
                type="date"
                min={moment().subtract(1, "days").format("YYYY-MM-DD")}
                name="startDate"
                value={moment(this.state.event.startTime).format("YYYY-MM-DD")}
                onChange={this.handleDateChange}
                valid={isStartDateValid(this.state.event)}
                invalid={!isStartDateValid(this.state.event)}
              />
              <FormFeedback>Future date is required.</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label for="startTime">Event Time</Label>
              <Input
                id="startTime"
                type="time"
                name="startTime"
                value={moment(this.state.event.startTime).format("HH:mm")}
                onChange={this.handleTimeChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="location">Event Location</Label>
          <Input
            id="location"
            name="location"
            value={this.state.event.location}
            onChange={this.handleInputChange}
            maxLength={100}
            type="text"
            placeholder="Event location"
            valid={isTextValid(this.state.event.location)}
            invalid={!isTextValid(this.state.event.location)}
          />
          <FormFeedback>Location is required.</FormFeedback>
        </FormGroup>
      </Form>
    );
  }
}

export default EventDetailsForm;
