import React, { ReactElement, useState } from "react";
import { useParams } from "react-router-dom";
import EventDetails from "./EventDetails";
import { EventModel, EventParams, State } from "./EventModel";
import EventProgress from "./EventProgress";
import NarrowContent from "./NarrowContent";
import NoEvent from "./NoEvent";
import useAPI from "./useAPI";

const EventSummary = (): ReactElement => {
  const { eventId } = useParams<EventParams>();
  const [event, setEvent] = useState<EventModel>();
  useAPI<EventModel>(
    `api/events/${eventId}`,
    {},
    setEvent,
  );
  return (
    <NarrowContent className="app-page">
      {event ? (
        <>
          <EventDetails event={event} />
          <dl>
            <dt>Host</dt>
            <dd>{event.host.displayName}</dd>
            <dt>Guests</dt>
            <dd>{event.guests.length}</dd>
            <dt>Playlists</dt>
            <dd>{event.playlists.length}</dd>
            <dt>State</dt>
            <dd>{State[event.state]}</dd>
          </dl>
          <EventProgress event={event} />
        </>
      ) : (
        <NoEvent />
      )}
    </NarrowContent>
  );
};

export default EventSummary;
