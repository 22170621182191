import React, { ReactElement } from "react";
import { Input, InputProps, Label } from "reactstrap";

const SwitchInput = (props: InputProps): ReactElement => (
  <div className="custom-control custom-switch">
    <Input
      type="checkbox"
      id={props.id}
      className="custom-control-input"
      checked={props.checked}
      onChange={props.onChange}
    />
    <Label className="custom-control-label" for={props.id}>
      {props.value}
    </Label>
  </div>
);

export default SwitchInput;
