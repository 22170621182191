import React, { ReactElement } from "react";
import { NavItem, NavLink } from "reactstrap";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";

const LoginMenu = (): ReactElement => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    <>
      <NavItem>
        <NavLink
          tag="a"
          className="text-dark"
          href={ApplicationPaths.IdentityManagePath}
        >
          Profile
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} className="text-dark" to="/logout">
          Logout
        </NavLink>
      </NavItem>
    </>
  ) : (
    <NavItem>
      <NavLink tag={Link} className="text-dark" to="/login">
        Login
      </NavLink>
    </NavItem>
  );
};

export default LoginMenu;
