import React, { Component, Fragment } from "react";
import {
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  FormText,
} from "reactstrap";
import StatusFooter from "./StatusFooter";
import {
  LoadState,
  PlaylistCategory,
  NewPlaylist,
} from "./EventModel";
import ActionsWrapper from "./ActionsWrapper";
import NumberInput from "./NumberInput";

interface PlaylistState extends LoadState {
  playlist: NewPlaylist;
}

interface PlaylistFormProps {
  category: PlaylistCategory;
  theme?: string;
  addPlaylist: (playlist: NewPlaylist) => Promise<void>;
}

class PlaylistForm extends Component<PlaylistFormProps, PlaylistState> {
  constructor(props: PlaylistFormProps) {
    super(props);
    this.state = {
      playlist: { title: props.theme, numberOfTracks: 1 },
    };

    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setError = this.setError.bind(this);
  }

  handleThemeChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const playlist = this.state.playlist;
    playlist.title = e.target.value;
    this.setState({ playlist: playlist });
  }

  handleNumberChange(e: React.ChangeEvent<HTMLInputElement>): void {
    let number = parseInt(e.target.value);
    if (isNaN(number)) number = 0;
    const playlist = this.state.playlist;
    playlist.numberOfTracks = number;
    this.setState({ playlist: playlist });
  }

  setStatus(m: string): void {
    this.setState({ status: m });
  }

  setError(e: Error): void {
    this.setState({ error: e });
  }

  async handleSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault();
    await this.props.addPlaylist(this.state.playlist);
  }

  render(): React.ReactNode {
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for="title">Playlist Theme</Label>
            <Input
              type="textarea"
              name="title"
              id="title"
              onChange={this.handleThemeChange}
              value={this.state.playlist.title}
              maxLength={100}
              placeholder="Enter theme..."
            ></Input>
          </FormGroup>
          <p>
            Heads Up! The duration of your event will depend on how many guests,
            playlist themes and songs per theme you select. An average song
            lasts approximately 3 minutes.
          </p>
          <FormGroup>
            <Label for="numberOfTracks">Songs per Guest</Label>
            <NumberInput
              name="numberOfTracks"
              id="numberOfTracks"
              min="1" 
              max="10"
              readOnly
              value={this.state.playlist.numberOfTracks}
              onChange={this.handleNumberChange}
            >
            </NumberInput>
            <FormText>
            Choose the number of songs you would like you and your guests to upload for this theme.
          </FormText>
          </FormGroup>
        </Form>
        <ActionsWrapper>
          <Button
            color="primary"
            disabled={!this.state.playlist.title}
            onClick={this.handleSubmit}
          >
            Confirm Playlist
          </Button>
        </ActionsWrapper>
        <StatusFooter status={this.state.status} error={this.state.error} />
      </Fragment>
    );
  }
}

export default PlaylistForm;
