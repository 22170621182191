import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import NoEvent from "./NoEvent";
import { Link } from "react-router-dom";
import { EventParams, EventSummaryModel } from "./EventModel";
import EventDetails from "./EventDetails";
import NarrowContent from "./NarrowContent";
import ActionsWrapper from "./ActionsWrapper";
import useAPI from "./useAPI";
import NewGuest from "./NewGuest";
import { useAuth } from "./api-authorization/AuthContext";

const JoinEvent = (): JSX.Element => {
  const { eventId } = useParams<EventParams>();
  const [createGuest, setCreateGuest] = useState(false);
  const [event, setEvent] = useState<EventSummaryModel>();
  const { isAuthenticated } = useAuth();
  useAPI<EventSummaryModel>(`api/events/${eventId}/summary`, {}, setEvent);
  return (
    <NarrowContent className="app-page">
      {event ? (
        <>
          <EventDetails event={event} />
          <p className="my-2">{event.description}</p>
          {!createGuest ? (
            <ActionsWrapper>
              {!isAuthenticated && (
                <Button color="primary" onClick={() => setCreateGuest(true)}>
                  Join as Guest &amp; Select Songs
                </Button>
              )}
              <Button color="primary" tag={Link} to={`/guest/${event.id}`}>
                {!isAuthenticated && "Login & "}Select Songs
              </Button>
            </ActionsWrapper>
          ) : (
            <NewGuest event={event} />
          )}
        </>
      ) : (
        <NoEvent></NoEvent>
      )}
    </NarrowContent>
  );
};

export default JoinEvent;
