import React, { ReactElement } from "react";
import NarrowContent from "./NarrowContent";
import MixopiaLogo from "../images/MIXOPIA_LOGO_RGB_BETA.svg";
import SocialMediaLinks from "./SocialMediaLinks";

const Footer = (): ReactElement => (
  <footer className="footer border-top">
    <NarrowContent className="text-center">
      <img height="24" src={MixopiaLogo} alt="Mixopia" />
      <div>
        <a href="https://www.mixopia.com/terms">Terms of Service</a>
      </div>
      <div>
        <a href="https://www.mixopia.com/privacy">Privacy Policy</a>
      </div>
      <div>
        <a href="https://www.mixopia.com/contact">Contact Us</a>
      </div>
      <div>
        <a href="https://www.mixopia.com/help">Help</a>
      </div>
      <SocialMediaLinks />
      <span>
        Copyright &copy; 2020-2024 Lifetracks Events Pty Ltd. All rights
        reserved
      </span>
    </NarrowContent>
  </footer>
);

export default Footer;
