import React, { Component, Fragment, ReactNode } from 'react';
import StatusFooter from './StatusFooter';
import { LoadState } from './EventModel';


class ErrorBoundary extends Component<React.PropsWithChildren, LoadState> {
  constructor(props: React.PropsWithChildren) {
    super(props);
    this.state = { status: ""};
  }

  static getDerivedStateFromError(error: Error): LoadState {
    return { error, status: "An error occurred"};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, status: "Unexpected error occurred."});
    console.error("Uncaught error:", error, errorInfo);
  }

  render(): ReactNode {
    return <Fragment>{this.props.children} 
    <StatusFooter status={this.state.status} error={this.state.error} />
    </Fragment>
  }
}

export default ErrorBoundary;