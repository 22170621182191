import React, { useEffect } from "react";
import { QueryParameterNames } from "./ApiAuthorizationConstants";

interface RedirectProps {
  path: string;
}

export const AuthRedirect: React.FC<RedirectProps> = ({ path }) => {
  const authUrl = `${path}?${QueryParameterNames.ReturnUrl}=/LoginCallback`;
  useEffect(() => {
    window.location.href = authUrl;
  }, [authUrl]);

  return null;
};
