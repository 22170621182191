export const ApplicationName =
  process.env.REACT_APP_CLIENT_APPLICATION_NAME ?? "Lifetrax.Web";

export const QueryParameterNames = {
  ReturnUrl: "returnUrl",
  Message: "message",
};

export const ApplicationPaths = {
  IdentityLoginPath: "/Identity/Account/Login",
  IdentityLogoutPath: "/Identity/Account/Logout",
  IdentityRegisterPath: "/Identity/Account/Register",
  IdentityManagePath: "/Identity/Account/Manage",
  IdentityConnectPath: "/Identity/Account/ConnectService",
};
