import React, { useState, ReactElement } from "react";
import { getResponse } from "../utils/apiUtils";
import StatusFooter from "./StatusFooter";
import NarrowContent from "./NarrowContent";
import Footer from "./Footer";
import {
  NewPlaylist,
  PlaylistModel,
} from "./EventModel";
import AddButton from "./AddButton";
import AddPlaylist from "./AddPlaylist";
import { FaChevronRight } from "react-icons/fa";
import { ListGroup, ListGroupItem, ListGroupItemHeading } from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "./api-authorization/AuthContext";
import useAPI from "./useAPI";
import { HostAction } from "./HostAction";

export const Playlists = (): ReactElement => {
  const { user } = useAuth();
  const [error, setError] = useState<Error>();
  const [addPlaylist, setAddPlaylist] = useState(false);  
  const [playlists, setPlaylists] = useState<PlaylistModel[]>();
  useAPI<PlaylistModel[]>(
    "api/playlists/",
    {},
    setPlaylists,
    setError
  );
  const toggleAddPlaylist = () => setAddPlaylist(!addPlaylist);
  async function addUserPlaylist(playlist: NewPlaylist): Promise<void> {
    await fetch(`api/playlists`, {
      method: "POST",
      body: JSON.stringify(playlist),
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((r) => getResponse(r, 201))
      .then((p) => p.json())
      .then((p) => playlists?.push(p))
      .then(toggleAddPlaylist)
      .catch((err) => {
        console.error(err);
        setError(err);
        return Promise.reject(err);
      });
  }

  function getPlaylistStatus(p: PlaylistModel): string {
    return ` ${p.selections?.length ?? 0} Songs Selected`;
  }

  return (
    <div className="app-page">
      <NarrowContent>
        <h1>Playlists {user?.displayName}</h1>
        {addPlaylist ? (
          <AddPlaylist
            title="Select Your Playlist Theme"
            onBack={toggleAddPlaylist}
            addPlaylist={(p) => addUserPlaylist(p)}
          />
        ) : (
          <>
            <HostAction user={user}><AddButton onClick={toggleAddPlaylist} value="Add a Playlist" /></HostAction>
            <ListGroup>
              {playlists?.map((p) => (
                <ListGroupItem
                  action
                  key={p.id}
                  tag={Link}
                  to={`/playlists/${p.id}`}
                >
                  <ListGroupItemHeading className="mb-0 d-flex align-items-center">
                    <div className="flex-grow-1">
                      <div>{p.title}</div>
                      <p className="body-copy-2 fw-light">
                        {p.owner?.displayName}
                      </p>
                      <p className="body-copy-2 fw-light">
                        {getPlaylistStatus(p)}
                      </p>
                    </div>
                    <FaChevronRight size="16" />
                  </ListGroupItemHeading>
                </ListGroupItem>
              ))}
            </ListGroup>
          </>
        )}
        <StatusFooter error={error} />
      </NarrowContent>
      <Footer />
    </div>
  );
};

export default Playlists;
